import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import GapAssessmentForm from "./GapAssessmentForm";
import { Card } from "@mui/material";

const GapAssessmentAdd: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [dataGridUrl, setDataGridUrl] = useState("");
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const handleOpenFeedbackModal = () => setOpenFeedbackModal(true);
  const handleOpenFeedbackFormModal = () => setOpenFeedbackFormModal(true);
  const [candidate, setCandidate] = useState({});
  const [openFeedbackFormModal, setOpenFeedbackFormModal] = useState(false);
  const handleCloseFeedbackFormModal = () => {
    navigate(-1);
  };
  useEffect(() => {
    setCandidate(state.candidate);
  }, [state.id]);

  return (
    <React.Fragment>
      <OlogaPageHeader title={"Atribuir Gap"}></OlogaPageHeader>
      <Card>
        <GapAssessmentForm
          onClose={handleCloseFeedbackFormModal}
          dto={state.dto}
          handleOpenGapList={handleOpenFeedbackModal}
        />
      </Card>
      <OlogaBackButton />
    </React.Fragment>
  );
};

export default GapAssessmentAdd;
