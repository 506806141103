import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import { GridColDef } from "@mui/x-data-grid";
import http from "../../utils/axios";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import { Box, Button, Card, Modal, Typography } from "@mui/material";
import GapAssessmentForm from "./GapAssessmentForm";

const GapAssessments: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [dataGridUrl, setDataGridUrl] = useState("");
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const handleOpenFeedbackModal = () => setOpenFeedbackModal(true);
  const handleOpenFeedbackFormModal = () => setOpenFeedbackFormModal(true);
  const [candidate, setCandidate] = useState({});
  const [openFeedbackFormModal, setOpenFeedbackFormModal] = useState(false);
  const handleCloseFeedbackFormModal = () => {
    setDataGridUrl(
      `/gap-assessment/candidate/${state.id}?timestamp=${new Date().getTime()}`
    );

    setOpenFeedbackFormModal(false);
  };
  useEffect(() => {
    const fetchGapDetails = async () => {
      try {
        setCandidate({ ...state.row, candidateId: state.id });
      } catch (error) {
        console.error("Error fetching gap details", error);
      }
    };
    setDataGridUrl(`/gap-assessment/candidate/${state.id}`);
    fetchGapDetails();
  }, [state.id]);

  const Gapcolumns: GridColDef[] = [
    {
      field: "analysCategory",
      headerName: "Categorias de Análise",
      flex: 2,
      editable: false,
    },
    {
      field: "gap",
      headerName: "Gap",
      flex: 3,
      editable: false,
    },
    {
      field: "specificAditionalNeeds",
      headerName: "Necessidades Adicionais Específicas",
      flex: 2,
      editable: false,
    },
    {
      field: "comments",
      headerName: "Comentários",
      flex: 2,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            const dto = {
              analysCategory: params.row.analysCategory,
              candidateId: state.id,
              gap: params.row.gap,
              specificAditionalNeeds: params.row.specificAditionalNeeds,
              comments: params.row.comments,
              id: params.id,
            };
            navigate(`/assessment-admin/gap-assessment/gaps/edit`, {
              state: {
                dto: dto,
                id: params.id,
              },
            });
          }}
        >
          Editar
        </Button>,
      ],
    },
  ];
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <React.Fragment>
      <OlogaPageHeader
        title={"Lista de Lacunas"}
        buttonLabel="Adicionar Lacuna"
        onClick={() => {
          const dto = {
            id: 0,
            candidateId: state.id,
          };
          navigate("/assessment-admin/gap-assessment/gaps/add", {
            state: {
              id: 0,
              dto: dto,
            },
          });
        }}
      ></OlogaPageHeader>
      <Card>
        <OlogaDataGrid url={dataGridUrl} columns={Gapcolumns} />
      </Card>

      <OlogaBackButton />
    </React.Fragment>
  );
};

export default GapAssessments;
