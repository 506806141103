import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Card as MuiCard,
  Grid,
  Button,
  Modal,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { Box, spacing } from "@mui/system";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import GapAssessmentForm from "./GapAssessmentForm";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import SearchIcon from "@mui/icons-material/Search";
import http from "../../utils/axios";
import { toast } from "react-toastify";
import { Formik } from "formik";
import ProjectLogForm from "./ProjectLogForm";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface dropdownDTO {
  text: string;
  value: number;
}

const ProjectLogDetails: React.FC = () => {
  const Card = styled(MuiCard)(spacing);
  const navigate = useNavigate();
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [openFeedbackFormModal, setOpenFeedbackFormModal] = useState(false);
  const [gapAssessmentDto, setGapAssessmentDto] = useState({});
  const handleOpenFeedbackModal = () => setOpenFeedbackModal(true);
  const handleCloseFeedbackModal = () => setOpenFeedbackModal(false);
  const handleOpenFeedbackFormModal = () => setOpenFeedbackFormModal(true);
  const handleCloseFeedbackFormModal = () => setOpenFeedbackFormModal(false);
  const [candidateName, setCandidateName] = useState("");
  const [candidate, setCandidate] = useState({});
  const [uri, setUri] = useState("");
  const [url, setUrl] = useState("/candidate");

  const [selectedProvince, setSelectedProvince] = useState<string | "">("");
  const [selectedSector, setSelectedSector] = useState<string | "">("");
  const [sectors, setSectors] = useState<dropdownDTO[]>([]);
  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const [selectedName, setSelectedName] = useState<string | "">("");
  const { state } = useLocation();
  const { id } = useParams<{ id: string }>();

  const logColumns: GridColDef[] = [
    {
      field: "projectName",
      headerName: "Nome do Projecto",
      flex: 2,
      editable: false,
    },
    {
      field: "executionPhase",
      headerName: "Fase de Execução",
      flex: 2,
      editable: false,
    },
    {
      field: "responsiblePerson",
      headerName: "Pessoa Responsável",
      flex: 2,
      editable: false,
    },
    {
      field: "comments",
      headerName: "Comentários",
      flex: 1.5,
      editable: false,
    },
    {
      field: "startDate",
      headerName: "Data de Início",
      flex: 1.5,
      editable: false,
    },
    {
      field: "pubDate",
      headerName: "Data de Término",
      flex: 1.5,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Acções",
      flex: 1,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            navigate(`/assessment-admin/project-log/edit/${id}`, {
              state: {
                // return URL [ uri: `/project-log/edit/${id}`, ]
                candidateId: id,
                dto: params.row ?? "",
              },
            });
          }}
        >
          Editar
        </Button>,
      ],
    },
  ];

  useEffect(() => {
    setCandidateName(state?.candidateName ?? "");
    setUri(`/project-log/candidate/${id}/PROJECT_LOG`);
  }, [url]);

  return (
    <React.Fragment>
      <OlogaPageHeader
        title={`Detalhe de Log de Projectos`}
        buttonLabel="Adicionar Log de Projecto"
        onClick={() => navigate(`/assessment-admin/project-log/edit/${id}`)}
      ></OlogaPageHeader>
      <Typography variant="h5" component="h1" mb={2} marginBottom={5}>
        Beneficiário Seleccionado: {candidateName}
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid url={uri} columns={logColumns} />
          </Card>

          <Modal
            open={openFeedbackFormModal}
            onClose={handleCloseFeedbackFormModal}
          >
            <Box sx={style}>
              <Typography variant="h6" component="h2" mb={2}>
                Formulário de Log de Projeto
              </Typography>
              <ProjectLogForm
                onClose={handleCloseFeedbackFormModal}
                dto={candidate}
                candidateId={id}
                handleOpenGapList={handleOpenFeedbackModal}
              />
            </Box>
          </Modal>
          <OlogaBackButton />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ProjectLogDetails;
