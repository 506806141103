import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Stepper,
  StepLabel,
  Step,
  StepIconProps,
  StepIcon,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import { Box, color, spacing } from "@mui/system";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import OlogaGenericCombo from "../../components/Combos/OlogaGenericCombo";
// import OlogaMultiInput from "../../components/Combos/OlogaMultiInput";
import Item from "../../components/Combos/Item";
import useAuth from "../../hooks/useAuth";
import React, { useState, useEffect, ChangeEvent } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CancelButton from "../../components/Buttons/CancelButton";
import SubmitButton from "../../components/Buttons/SubmitButton";
import axios from "../../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import ImageCompressor from "../../hooks/ImageCompressor";
import OlogaLoading from "../../components/misc/OlogaLoading";
import { Answer, AssessmentDto, Question, Tool } from "./data_mapping";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { string } from "yup";
import OlogaAutocomplete from "../../components/select/OlogaAutocomplete";
import AddQuestionModal from "../../components/modal/AddQuestionModal";
import OlogaDatePicker from "../../components/inputfields/OlogaDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const FormBuilder: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [media, setMedia] = useState<FormData>();
  const [AssessmentScoreDate, setAssessmentScoreDate] = useState("");
  const [questions, setQuestions] = useState<Question[]>([]);
  const [productBrands, setProductBrands] = useState<Item[]>([]);
  const [candidates, setCandidates] = useState<Item[]>([]);
  const [assessmentDtos, setAssessmentDtos] = useState<AssessmentDto[]>([]);
  const map = new Map();
  const [dataForm, setDataForm] = useState({});
  const [textControllerState, setTextControllerState] = useState({ id: null });
  const [sizeType, setSizeType] = useState("");
  const [radio, setRadio] = useState("");
  const [assessmentDate, setAssessmentDate] = useState<any | " ">("");
  const [submitted, setSubmitted] = useState(false);
  const [isValueChanged, setIsValueChanged] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [previousValue, setPreviousValue] = useState();
  const [currentValue, setCurrentValue] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [showCandidates, setShowCandidate] = useState(false);
  const [value, setValue] = useState(0);
  const [candidateName, setCandidateName] = useState<any>();
  const [candidateId, setCandidateId] = useState<any>();
  const [selectedCandidate, setSelectedCandidate] = useState<any>();
  const [assessmentId, setAssessmentID] = useState(0);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [steps, setSteps] = useState<[]>([]);
  const [dataDTO, setDataDto] = useState<Map<string, string>>(new Map());
  const [mapDataDto, setMapDataDto] = useState<Map<number, AssessmentDto>>(
    new Map()
  );
  const [radioValues, setRadioValues] = useState<Map<string, string>>(
    new Map()
  );
  const CustomStepIcon = (props: StepIconProps) => {
    const { active, completed, icon } = props;
  };
  const [selectedImage, setSelectedImage] = useState<
    string | ArrayBuffer | null
  >(null);
  const [tool, setTool] = useState<Tool>({
    id: 0,
    name: "",
    description: "",
  });
  const [textController, setTextController] = useState({
    questionId: 0,
    answer: "",
  });

  const [assessmentAnswers, setAssessmentAnswers] = useState([
    {
      id: null,
      updated: false,
      score: null,
      name: "",
      description: "",
      question: {
        id: null,
        updated: false,
        name: "",
        description: "",
        category: {
          id: null,
          updated: false,
          code: null,
          type: "",
          description: "",
          parentId: null,
          state: null,
          vendorId: null,
        },
        type: {
          id: null,
          updated: false,
          code: "",
          type: "",
          description: "",
          parentId: null,
          state: null,
          vendorId: null,
        },
        tool: {
          id: null,
          updated: false,
          name: "",
          description: "",
          assessment: {
            id: null,
            updated: false,
            name: "",
          },
        },
      },
    },
  ]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    question: Question
  ) => {
    setSubmitted(false);
    const { name, value } = event.target;
    const property = "answers.".concat(question.id.toString());
  };

  const handleInputChangeRadio = (
    event: React.ChangeEvent<HTMLInputElement>,
    question: Question
  ) => {
    setSubmitted(false);
    const { name, value } = event.target;
  };

  const handleSelectChange = (answer: Answer, question: Question) => {
    updateVariable(dataForm, `${question.id}`, answer, null);
    setAnswerDto(answer, question);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const setAnswerDto = (answer: Answer, question: Question) => {
    const newAssessmentDto: AssessmentDto = {
      flag: question.name,
      candidateId:
        state?.dto?.candidateId != null
          ? state?.dto?.candidateId
          : selectedCandidate.id,
      assessmentId: question.tool.assessment.id,
      toolId: question.tool.id,
      questionId: question.id,
      answer: {
        id: answer.id,
        name: answer.description,
        question: { id: question.id },
        score: answer.score,
        description: answer.description,
      },
      assessmentDate: "",
    };
    mapDataDto.set(question.id, newAssessmentDto);
  };

  const updateVariable = (
    object: any,
    propertyName: string,
    newValue: any,
    e: any
  ) => {
    const propertyPath = propertyName.split(".");
    let currentObject: any = { ...object };

    if (propertyPath.length > 1) {
      for (let i = 0; i < propertyPath.length - 1; i++) {
        currentObject = currentObject[propertyPath[i]];
      }
      currentObject[propertyPath[propertyPath.length - 1]] = newValue;
      setDataForm({ ...object });
    } else if (propertyPath.length == 1) {
      currentObject[propertyName] = newValue;
      setDataForm({ ...currentObject });
    }
  };

  // Função utilitária para acessibilidade
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const retrieveQuestionsCategories = async (toolId: number) => {
    const response = await axios.get(
      `simple-entity/questioncategories/${toolId}`
    );
    setSteps(
      response.data.map((e: any) => {
        return {
          id: e?.id,
          type: e?.type,
          description: e?.description,
        };
      })
    );

    setActiveStep(response.data[0].id);
  };

  const retrieveQuestions = async (toolId: number) => {
    const response = await axios.get(`questions/${toolId}`);
    setQuestions(
      response.data.map((e: any) => {
        return {
          id: e?.id,
          name: e?.name,
          description: e?.description,
          category: e?.category,
          categoryId: e?.category.id,
          type: e?.type,
          tool: e?.tool,
          answers: e.answers,
        };
      })
    );
  };

  const retrieveTool = async (toolId: number) => {
    const response = await axios.get(`tools/${toolId}`);
    setTool(response.data);
    setAssessmentID(response.data.assessment.id);
  };

  const retrieveAssessmentScoreItems = async (
    toolId: number,
    assessmentScoreId: number
  ) => {
    if (state?.dto?.id) {
      const response = await axios.get(
        `assessments/getAssessmentScoreItems/${toolId}/${assessmentScoreId}`
      );

      (response as any).data.forEach((element: any) => {
        updateVariable(
          dataForm,
          `${element.question.id}`,
          element.answer,
          null
        );
        mapDataDto.set(element.question.id, element);
        setAssessmentDate(element.assessmentDate);
      });
    }
  };

  const retrieveCandidates = async () => {
    const response = await axios.get(`candidate/list`);
    setCandidates(
      response.data.map((e: any) => {
        return {
          id: e.id,
          description: e.firstName,
          type: e.firstName,
          name: e.firstName,
          value: e.id,
        };
      })
    );
  };

  const handleSubmit = async (event: React.FormEvent) => {
    var response;

    try {
      var dtoAssessScoreItems: any = [];
      mapDataDto.forEach((value, key) => {
        value.assessmentDate = assessmentDate;
        value.candidateId =
          state?.dto?.candidateId != null
            ? state?.dto?.candidateId
            : candidateId;
        dtoAssessScoreItems.push(value);
      });

      response = await axios.post(
        `assessments/assessmentScore/${state?.dto?.id ? state?.dto?.id : 0}`,
        dtoAssessScoreItems
      );

      navigate(`/candidates/create`);
      toast.success("Operação realizada com sucesso");
    } catch (error) {
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    }

    setShowLoading(false);
  };

  useEffect(() => {
    retrieveTool(state?.dto?.toolId);
    retrieveQuestionsCategories(state?.dto?.toolId);
    retrieveQuestions(state?.dto?.toolId);
    retrieveTool(state?.dto?.toolId);
    retrieveCandidates();
    retrieveAssessmentScoreItems(state?.dto?.toolId, state?.dto?.id);
    setCandidateName(state?.dto?.candidateName);
    setCandidateId(state?.dto?.candidateId);
    const parsedDate = dayjs(state?.dto?.assessmentDate);
    if (parsedDate.isValid()) {
      setAssessmentDate(parsedDate);
    } else {
      console.error("Invalid date format:", state?.dto?.assessmentDate);
    }
  }, []);

  return (
    <React.Fragment>
      <OlogaPageHeader
        title={state?.dto?.toolDescription}
        items={[{ label: "Tool", url: state?.dto?.returnUrl }]}
      />
      <Card mb={12}>
        <OlogaLoading open={showLoading} />
        <CardContent>
          <Grid container spacing={3}>
            {/* Candidate Name and Selection Button */}
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Typography variant="h6">
                    Candidato: {state?.dto?.candidateName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      navigate(`/assessment-admin/candidateselection`, {
                        state: {
                          returnUrl: "/tools/form",
                          dto: {
                            id: state?.dto?.id,
                            toolId: state?.dto?.toolId,
                            assessmentId: state?.dto?.assessmentId,
                            candidateId: state?.dto?.candidateId,
                            toolDescription: state?.dto?.toolDescription,
                            toolName: state?.dto?.toolName,
                            assessmentDate: state?.dto?.assessmentDate,
                          },
                        },
                      });
                    }}
                  >
                    Seleccionar Candidato
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {/* Assessment Date */}
            <Grid item xs={12} md={6} mt={5}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Data da Avaliação"
                  format="DD/MM/YYYY"
                  value={assessmentDate} // Ensure `assessmentDate` is a valid dayjs object or null
                  onChange={(newDate) => {
                    setAssessmentDate(newDate); // Directly set the selected date
                  }}
                  slotProps={{
                    textField: {
                      name: "assessmentDate",
                      fullWidth: true,
                      variant: "outlined",
                      helperText: "",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <br />
          <br />
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="steps-tabs"
          >
            {steps.map((step, index) => (
              <Tab
                key={(step as any).id}
                label={(step as any).description}
                {...a11yProps(index)}
                onClick={() => handleStepChange((step as any).id)}
              />
            ))}
          </Tabs>

          <br />
          <form onSubmit={handleSubmit}>
            {true && (
              <Box p={3}>
                <Grid container spacing={2} marginBottom={3}>
                  {questions.map((question: Question) => {
                    const matchingAnswers = assessmentAnswers.filter(
                      (a) => a?.question.id === question.id
                    );
                    switch (question.type.code.toLowerCase()) {
                      case "dropdown":
                        return (question as any).categoryId === activeStep ? (
                          <Grid item xs={5} key={question.id} md={6}>
                            <p>{question.description}</p>
                            <OlogaGenericCombo
                              label={""}
                              name={`dataForm.${question.id}`}
                              items={question.answers}
                              value={mapDataDto?.get(question.id)?.answer || ""}
                              onChange={(valueInput: Answer) =>
                                handleSelectChange(valueInput, question)
                              }
                              required={false}
                            />
                          </Grid>
                        ) : null;
                      case "text":
                        return (question as any).categoryId === activeStep ? (
                          <Grid item xs={6} key={question.id}>
                            <p>{question.name}</p>
                            <TextField
                              key={question.id}
                              fullWidth
                              name={question.description}
                              /*   value={
                              matchingAnswers.length > 0
                                ? matchingAnswers[0]?.description
                                : ""
                            } */
                              variant="outlined"
                              onChange={(valueInput) => {
                                const newText = {
                                  questionId: question.id,
                                  answer: valueInput.target.value,
                                };

                                if (matchingAnswers.length > 0) {
                                  matchingAnswers[0].description =
                                    valueInput.target.value;
                                  matchingAnswers[0] = matchingAnswers[0];
                                }

                                setTextController((prev) => ({
                                  ...prev,
                                  newText,
                                }));
                                handleInputChange(
                                  valueInput as React.ChangeEvent<HTMLTextAreaElement>,
                                  question
                                );
                              }}
                              required
                              error={submitted && !question.name}
                              helperText={
                                submitted &&
                                !question.name &&
                                "This field is required"
                              }
                            />
                          </Grid>
                        ) : null;
                      case "radio":
                        return (question as any).categoryId === activeStep ? (
                          <Grid
                            item
                            xs={6}
                            marginBottom={3}
                            key={`${question.id}-${question.description}`}
                          >
                            <p>{question.name}</p>

                            <RadioGroup
                              key={`${question.id}-${question.description}`}
                              aria-label="options"
                              name={question.name}
                              value={radioValues.get(question.description)}
                              onChange={(valueInput) =>
                                handleInputChangeRadio(valueInput, question)
                              }
                            >
                              {question.answers.map((item) => (
                                <FormControlLabel
                                  key={`${question.id}-${question.description}`}
                                  value={item["name"]}
                                  control={<Radio />}
                                  label={item["name"]}
                                />
                              ))}
                            </RadioGroup>
                          </Grid>
                        ) : null;
                      case "button":
                        return (
                          <Grid item xs={6} key={question.id}>
                            <Grid item className="form-buttons">
                              <SubmitButton
                                label={"Save and Create New"}
                                onClick={() => {}}
                              />
                            </Grid>
                          </Grid>
                        );
                    }
                  })}
                  <Grid
                    container
                    marginTop={5}
                    spacing={3}
                    mt={2}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {" "}
                    <Grid item className="form-buttons">
                      <SubmitButton label={"Save"} onClick={handleSubmit} />
                    </Grid>
                    <Grid item marginRight={2}>
                      <SubmitButton
                        label={"Cancel"}
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default FormBuilder;
