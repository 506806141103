import React, { useEffect } from "react";
import CandidateForm from "./CandidateForm";
import useAuth from "../../hooks/useAuth";

const CandidateCreate: React.FC = () => {
  const { user } = useAuth();
  useEffect(() => {}, []);

  return <CandidateForm id={0} userType={user?.roleName} />;
};

export default CandidateCreate;
