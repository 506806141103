import React, { useEffect, useState } from "react";
import Deleteicon from "@mui/icons-material/Delete";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { toast } from "react-toastify";
import http from "../../utils/axios";
import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import PerformanceIndicatorForm from "./PerformanceIndicatorForm";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";

interface PerformanceIndicator {
  id: number;
  name: string;
  targetValue: number;
  currentValue: number;
  unitOfMeasure: string;
  period: string;
  indicatorType: string;
  color: string;
  dashboardType: string;
  sqlQuery?: string;
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PerformanceIndicatorList: React.FC = () => {
  const [indicators, setIndicators] = useState<PerformanceIndicator[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [open, setOpen] = useState<boolean>(false);
  const [editingIndicator, setEditingIndicator] =
    useState<PerformanceIndicator | null>(null);
  const [totalIndicators, setTotalIndicators] = useState<number>(0);

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
  const [indicatorToDelete, setIndicatorToDelete] = useState<number | null>(
    null
  );

  const fetchIndicators = async (page: number, rowsPerPage: number) => {
    const response = await http.get(
      `/performanceIndicator?page=${page}&size=${rowsPerPage}`
    );
    setIndicators(response.data.content);
    setTotalIndicators(response.data.totalElements);
  };

  useEffect(() => {
    fetchIndicators(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleOpen = (indicator: PerformanceIndicator | null) => {
    setEditingIndicator(indicator);
    setOpen(true);
  };

  const handleClose = () => {
    setEditingIndicator(null);
    setOpen(false);
    fetchIndicators(page, rowsPerPage);
  };

  const handleDeleteOpen = (id: number) => {
    setIndicatorToDelete(id);
    setConfirmDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setConfirmDeleteOpen(false);
    setIndicatorToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (indicatorToDelete) {
      try {
        await http.delete(`/performanceIndicator/${indicatorToDelete}`);
        fetchIndicators(page, rowsPerPage);
        toast.success("Operação realizada com sucesso");
        handleDeleteClose();
      } catch (error) {
        console.error(error);
        toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
      }
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <OlogaPageHeader
        title={"Indicadores de Desempenho"}
        buttonLabel="Novo Indicador de Desempenho"
        onClick={() => handleOpen(null)}
      />
      <Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "black" }}>
              <TableRow>
                {[
                  "Nome",
                  "Valor Alvo",
                  "Valor Atual",
                  "Unidade de Medida",
                  "Período",
                  "Tipo de Indicador",
                  "Ações",
                ].map((header, index) => (
                  <TableCell key={index} sx={{ color: "white" }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {indicators.map((indicator) => (
                <TableRow key={indicator.id}>
                  <TableCell>{indicator.name}</TableCell>
                  <TableCell>{indicator.targetValue}</TableCell>
                  <TableCell>{indicator.currentValue}</TableCell>
                  <TableCell>{indicator.unitOfMeasure}</TableCell>
                  <TableCell>{indicator.period}</TableCell>
                  <TableCell>{indicator.indicatorType}</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<VisibilityOutlinedIcon />}
                      onClick={() => handleOpen(indicator)}
                      style={{ marginRight: "10px" }}
                    >
                      ver
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<Deleteicon />}
                      onClick={() => handleDeleteOpen(indicator.id)}
                    >
                      Apagar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalIndicators}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        {/* Performance Indicator Form Modal */}
        <Modal open={open} onClose={handleClose}>
          <Box sx={modalStyle}>
            <PerformanceIndicatorForm
              indicator={editingIndicator}
              onClose={handleClose}
            />
          </Box>
        </Modal>

        <Dialog open={confirmDeleteOpen} onClose={handleDeleteClose}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            Tem a certeza de que deseja eliminar este indicador de desempenho?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose}>Cancelar</Button>
            <Button onClick={handleConfirmDelete} color="error">
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default PerformanceIndicatorList;
