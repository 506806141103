import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import { Drawer as MuiDrawer, ListItemButton } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo-hr-white-tr.svg";

import { SidebarItemsType } from "../../types/sidebar";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";

const Drawer = styled(MuiDrawer)`
  > div {
    border-right: 0;
    background-color: ${(props) =>
      props.theme.sidebar.background}; /* Sidebar background */
    color: ${(props) => props.theme.sidebar.color}; /* Sidebar text color */
  }
`;

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color}; /* Header text color */
  background-color: ${(props) =>
    props.theme.sidebar.header.background}; /* Header background */
  font-family: ${(props) => props.theme.typography.fontFamily};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;
  //padding: -14px; /* Adjust padding for better spacing */

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px; /* Header height */
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 180px; /* Adjust logo size */
  height: auto;
`;

const NavContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  height: 100px;
`;

const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  text-decoration: none;
  color: ${(props) => props.theme.sidebar.color};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-size: ${(props) => props.theme.typography.body1.fontSize};
  border-radius: 4px;

  &.active {
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) =>
      props.theme.sidebar.activeBackground || "rgba(0, 0, 0, 0.15)"};
  }
`;

export type SidebarProps = {
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
  onClose?: () => void;
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  showFooter?: boolean;
};

const Sidebar: React.FC<SidebarProps> = ({
  items,
  showFooter = true,
  ...rest
}) => {
  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink as any} to="/dashboard">
        <BrandIcon />
      </Brand>
      <NavContainer>
        <SidebarNav items={items} />
      </NavContainer>
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
