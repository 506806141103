import React, { useEffect, useState } from "react";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import {
  Card as MuiCard,
  Grid,
  Button,
  Modal,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { Box, spacing } from "@mui/system";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import DemandMappingForm from "./DemandMappingForm";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import { Formik } from "formik";
import http from "../../utils/axios";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
interface dropdownDTO {
  text: string;
  value: number;
}

interface SearchDTO {
  sector: string;
  description: string;
}

const ToolDemandMapping: React.FC = () => {
  const Card = styled(MuiCard)(spacing);
  const navigate = useNavigate();
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [demandDto, setDemandDto] = useState({});
  const handleOpenFeedbackModal = () => setOpenFeedbackModal(true);
  const handleCloseFeedbackModal = () => setOpenFeedbackModal(false);
  const [url, setUrl] = useState("/demand/page");
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Descrição do Projecto/Oportunidade",
      flex: 3,
      editable: false,
    },
    {
      field: "province",
      headerName: "Provincia",
      flex: 1.5,
      editable: false,
    },
    {
      field: "district",
      headerName: "Distrito",
      flex: 1.5,
      editable: false,
    },
    {
      field: "subActivitySector",
      headerName: "Sub-Sector de Actividade",
      flex: 2,
      editable: false,
    },
    {
      field: "startDate",
      headerName: "Data de Inicio",
      flex: 1,
      editable: false,
    },
    {
      field: "endDate",
      headerName: "Data de Fim",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Acções",
      flex: 1.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<OlogaButton variant="outlined" label="Editar" />}
          onClick={async () => {
            console.log(params.row);
            setDemandDto(params.row as any);
            handleOpenFeedbackModal();
          }}
          label="Editar"
        />,
      ],
    },
  ];

  const handleSubmit = async (values: any) => {
    try {
      const params = new URLSearchParams();

      for (const key in values) {
        if (values.hasOwnProperty(key) && values[key]) {
          params.append(key, values[key]);
        }
      }
      setUrl(`/demand/page?${params.toString()}`);
    } catch (error: any) {
      toast.error(
        "Ocorreu o seguinte erro ao realizar a operação: " + error.message
      );
    }
  };

  return (
    <React.Fragment>
      <OlogaPageHeader
        title={"Mapeamento de Demanda"}
        buttonLabel="Adicionar Nova Demanda"
        onClick={handleOpenFeedbackModal}
      ></OlogaPageHeader>
      <Grid container>
        <Grid item xs={12}>
          <Formik initialValues={{}} enableReinitialize onSubmit={handleSubmit}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4} mb={3}>
                  <Grid item xs={12} md={10}>
                    <OlogaTextfield name="search" label="Pesquisa." />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <OlogaButton
                      type="submit"
                      icon={<SearchIcon />}
                      label="Pesquisar"
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid url={url} columns={columns} />
          </Card>

          <Modal open={openFeedbackModal} onClose={handleCloseFeedbackModal}>
            <Box sx={style}>
              <Typography variant="h6" component="h2" mb={2}>
                Formulário de Mapeamento de Demanda
              </Typography>
              <DemandMappingForm
                onClose={handleCloseFeedbackModal}
                dto={demandDto}
              />
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ToolDemandMapping;
