import React, { useState, useEffect, useRef } from "react";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import http from "../../utils/axios";
import { toast } from "react-toastify";
import {
  FormControl,
  Typography,
  CircularProgress,
  CardContent,
  Card,
  CardHeader,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";

interface Candidate {
  id: number;
  demand: number;
  candidateReference: string;
  candidateName: string;
  companyFoundationYear: number;
  category: string;
  province: string;
  district: string;
  companyType: string;
  activitySector: string;
  numberOfEmployees: string;
  haveAcount: boolean;
}

interface IDemand {
  id: number;
  offer: number;
  reference: string;
  responsable: string;
  description: string;
  name: string;
  subActivitySector: string;
  province: string;
  district: string;
  location: string;
  executionPhase: string;
  perpectiveDuration: string;
  pubDate: string;
  startDate: string;
  endDate: string;
}

interface dropdownDTO {
  text: string;
  value: number;
}

const MatchmakingTool: React.FC = () => {
  const searchButtonRef = useRef<HTMLButtonElement | null>(null);
  const [sectors, setSectors] = useState<dropdownDTO[]>([]);
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [demands, setDemands] = useState<IDemand[]>([]);
  const [selectedSector, setSelectedSector] = useState<string | "">("");
  const [selectedProvince, setSelectedProvince] = useState<string | "">("");
  const [filteredOffers, setFilteredOffers] = useState<Candidate[]>([]);
  const [filteredDemands, setFilteredDemands] = useState<IDemand[]>([]);
  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [view, setView] = useState<"offer" | "demand">("offer");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<Candidate[] | IDemand[]>([]);
  const [selectedId, SetSelectedId] = useState(0);

  const fetchData = async () => {
    try {
      const ACTIVITY_SECTOR = await http.get(
        `simple-entity/findDropdown/ACTIVITY_SECTOR`
      );
      setSectors(ACTIVITY_SECTOR.data);

      const response = await http.get(`simple-entity/findDropdown/PROVINCE`);
      setDropdown_province(response.data);

      const candidates_ = await http.get(`/candidate/list`);
      setCandidates(candidates_.data);

      const _demands = await http.get(`/demand/demands`);
      setDemands(_demands.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleSectorChange = (event: any) => {
    setSelectedSector(event.target.value);
  };

  const handleProvinceChange = (event: any) => {
    setSelectedProvince(event.target.value);
  };

  const handleSearch = () => {
    const ofertas = candidates.filter(
      (o) =>
        (selectedSector === "" || o.activitySector === selectedSector) &&
        (selectedProvince === "" || o.province === selectedProvince)
    );
    setFilteredOffers(ofertas);

    const demandas = demands.filter(
      (d) =>
        (selectedSector === "" || d.subActivitySector === selectedSector) &&
        (selectedProvince === "" || d.province === selectedProvince)
    );
    setFilteredDemands(demandas);
  };

  const handleOpenModal = (
    type: "offer" | "demand",
    id: number,
    associatedId: number
  ) => {
    console.log("associatedId", associatedId);
    if (associatedId === 0) {
      if (type === "offer") {
        setModalContent(filteredDemands);
      } else {
        setModalContent(filteredOffers);
      }
    } else {
      if (type === "offer") {
        const relatedDemand = demands.filter((demand) => demand.offer === id);
        setModalContent(relatedDemand);
      } else {
        const relatedOffer = candidates.filter((offer) => offer.demand === id);
        setModalContent(relatedOffer);
      }
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAssociate = async (demandId: number, offerId: number) => {
    try {
      const association =
        view === "offer"
          ? { offerId: selectedId, demandId }
          : { offerId, demandId: selectedId };
      await http.post(`/demand/associate`, association);
      await fetchData();
      toast.success("Operação realizada com sucesso");
      searchButtonRef.current?.click();
      handleCloseModal();
    } catch (error) {
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <OlogaPageHeader title={"Ferramenta para o Matchmaking"} />

      <Card>
        <CardHeader title="Ferramenta de Matchmaking" />
        <CardContent>
          {/* Formulário de pesquisa */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Sector de Actividade</InputLabel>
                <Select
                  value={selectedSector}
                  onChange={handleSectorChange}
                  label="Sector de Actividade"
                >
                  <MenuItem value="">
                    <em>Selecione um setor</em>
                  </MenuItem>
                  {sectors.map((sector) => (
                    <MenuItem key={sector.value} value={sector.text}>
                      {sector.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Província</InputLabel>
                <Select
                  value={selectedProvince}
                  onChange={handleProvinceChange}
                  label="Província"
                >
                  <MenuItem value="">
                    <em>Selecione uma província</em>
                  </MenuItem>
                  {dropdown_province.map((province) => (
                    <MenuItem key={province.value} value={province.text}>
                      {province.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button
                ref={searchButtonRef}
                variant="contained"
                color="primary"
                onClick={handleSearch}
                sx={{ marginTop: 2 }}
              >
                Pesquisar
              </Button>
            </Grid>
          </Grid>

          {/* Alternar entre tabelas */}
          <Box sx={{ marginTop: 3 }}>
            <RadioGroup
              row
              value={view}
              onChange={(e) => setView(e.target.value as "offer" | "demand")}
            >
              <FormControlLabel
                value="offer"
                control={<Radio />}
                label="Ofertas"
              />
              <FormControlLabel
                value="demand"
                control={<Radio />}
                label="Demandas"
              />
            </RadioGroup>
          </Box>

          {/* Renderização condicional da tabela */}
          {view === "offer" ? (
            <TableContainer component={Paper} sx={{ marginTop: 3 }}>
              <Table>
                <TableHead sx={{ backgroundColor: "black" }}>
                  <TableRow>
                    {[
                      "Nome",
                      "Ano de Fundação",
                      "Categoria",
                      "Província",
                      "Distrito",
                      "Tipo de Empresa",
                      "Número de Funcionários",
                      "Tem Conta?",
                      "Ações",
                    ].map((header, index) => (
                      <TableCell key={index} sx={{ color: "white" }}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredOffers.length > 0 ? (
                    filteredOffers.map((offer) => (
                      <TableRow key={offer.id}>
                        <TableCell>{offer.candidateName}</TableCell>
                        <TableCell>{offer.companyFoundationYear}</TableCell>
                        <TableCell>{offer.category}</TableCell>
                        <TableCell>{offer.province}</TableCell>
                        <TableCell>{offer.district}</TableCell>
                        <TableCell>{offer.companyType}</TableCell>
                        <TableCell>{offer.numberOfEmployees}</TableCell>
                        <TableCell>
                          {offer.haveAcount ? "Sim" : "Não"}
                        </TableCell>
                        <TableCell>
                          <Button
                            style={{ width: "200px" }}
                            variant="outlined"
                            color="secondary"
                            startIcon={
                              offer.demand <= 0 ? (
                                <EditIcon />
                              ) : (
                                <VisibilityOutlinedIcon />
                              )
                            }
                            onClick={() => {
                              handleOpenModal("offer", offer.id, offer.demand);
                              SetSelectedId(offer.id);
                            }}
                          >
                            {offer.demand <= 0
                              ? "Associar Demanda"
                              : "Ver Demanda"}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        Nenhuma oferta encontrada.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer component={Paper} sx={{ marginTop: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Responsável</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Subsector de Atividade</TableCell>
                    <TableCell>Província</TableCell>
                    <TableCell>Distrito</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredDemands.length > 0 ? (
                    filteredDemands.map((demand) => (
                      <TableRow key={demand.id}>
                        <TableCell>{demand.responsable}</TableCell>
                        <TableCell>{demand.description}</TableCell>
                        <TableCell>{demand.subActivitySector}</TableCell>
                        <TableCell>{demand.province}</TableCell>
                        <TableCell>{demand.district}</TableCell>
                        <TableCell>
                          <Button
                            style={{ width: "200px" }}
                            variant="outlined"
                            color="secondary"
                            startIcon={
                              demand.offer <= 0 ? (
                                <EditIcon />
                              ) : (
                                <VisibilityOutlinedIcon />
                              )
                            }
                            onClick={() => {
                              handleOpenModal(
                                "demand",
                                demand.id,
                                demand.offer
                              );
                              SetSelectedId(demand.id);
                            }}
                          >
                            {demand.offer <= 0
                              ? "Associar Oferta"
                              : "Ver Oferta"}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        Nenhuma demanda encontrada.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>

      <Dialog maxWidth="lg" open={openModal} onClose={handleCloseModal}>
        <DialogTitle>
          {view === "offer" ? "Associar Demanda" : "Associar Oferta"}
        </DialogTitle>
        <DialogContent>
          {/* Tabela para "offer" */}
          {view === "offer" ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Demanda</TableCell>
                  <TableCell>Responsável</TableCell>
                  <TableCell>Categoria</TableCell>
                  <TableCell>Província</TableCell>
                  <TableCell>Distrito</TableCell>
                  <TableCell>Tipo de Empresa</TableCell>
                  <TableCell>Número de Funcionários</TableCell>
                  <TableCell>Tem Conta?</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {modalContent.length > 0 ? (
                  modalContent.map((item: any, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.responsable}</TableCell>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>{item.province}</TableCell>
                      <TableCell>{item.district}</TableCell>
                      <TableCell>{item.companyType}</TableCell>
                      <TableCell>{item.numberOfEmployees}</TableCell>
                      <TableCell>{item.haveAcount ? "Sim" : "Não"}</TableCell>
                      <TableCell>
                        {(item.offer <= 0 || item.demand <= 0) && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleAssociate(
                                item.id,
                                view === "offer" ? 0 : item.id
                              )
                            }
                          >
                            Associar
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      Nenhuma demanda encontrada.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          ) : (
            // Tabela para "demand"
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome do Candidato</TableCell>
                  <TableCell>Responsável</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Subsector de Atividade</TableCell>
                  <TableCell>Província</TableCell>
                  <TableCell>Distrito</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {modalContent.length > 0 ? (
                  modalContent.map((item: any, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.candidateName}</TableCell>
                      <TableCell>{item.responsable}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.subActivitySector}</TableCell>
                      <TableCell>{item.province}</TableCell>
                      <TableCell>{item.district}</TableCell>
                      <TableCell>
                        {(item.offer <= 0 || item.demand <= 0) && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              handleAssociate(
                                view === "demand" ? 0 : item.id,
                                item.id
                              );
                            }}
                          >
                            Associar
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      Nenhuma oferta encontrada.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MatchmakingTool;
