import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Card as MuiCard,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import { Formik } from "formik";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import OlogaSelect from "../../components/select/OlogaSelect";
import http from "../../utils/axios";

interface dropdownDTO {
  text: string;
  value: number;
}

const ToolSupplyMapping: React.FC = () => {
  const Card = styled(MuiCard)(spacing);
  const navigate = useNavigate();
  const [url, setUrl] = useState("/candidate/search-supply");
  const [selectedProvince, setSelectedProvince] = useState<string | "">("");
  const [selectedSector, setSelectedSector] = useState<string | "">("");
  const [sectors, setSectors] = useState<dropdownDTO[]>([]);
  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const [selectedName, setSelectedName] = useState<string | "">("");
  const [query, setQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<any>(null);
  const [dropdown_activitySector, setDropdown_activitySector] = useState<
    dropdownDTO[]
  >([]);
  const columns: GridColDef[] = [
    {
      field: "candidateName",
      headerName: "Nome do Beneficiario",
      flex: 1,
      editable: false,
    },
    {
      field: "province",
      headerName: "Provincia",
      flex: 1,
      editable: false,
    },
    {
      field: "size",
      headerName: "Dimensão",
      flex: 1,
      editable: false,
    },
    {
      field: "activitySector",
      headerName: "Sector de Actividade",
      flex: 1,
      editable: false,
    },
    {
      field: "desiredAssistance",
      headerName: "Tipo de Assistência",
      flex: 1,
      editable: false,
    },
    {
      field: "previouslyBenefited",
      headerName: "Anteriormente Beneficiado",
      flex: 2,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <Typography>
          {params.row.previouslyBenefited ? "Sim" : "Não"}
        </Typography>,
      ],
    },
    {
      field: "actions",
      headerName: "Ações",
      flex: 1,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <Button
          size="small"
          variant="outlined"
          startIcon={<VisibilityOutlinedIcon />}
          onClick={() => {
            setSelectedCandidate(params.row);
            setOpenModal(true);
          }}
        >
          Ver Detalhes
        </Button>,
      ],
    },
  ];

  const handleSubmit = async (values: any) => {
    try {
      if (selectedName != "" || selectedName.length > 0) {
        values["candidateName"] = selectedName;
      }
      if (selectedSector != "" || selectedSector.length > 0) {
        values["activitySector"] = selectedSector;
      }
      if (selectedProvince != "" || selectedProvince.length > 0) {
        values["province"] = selectedProvince;
      }
      let q = "";

      for (let key in values) {
        if (values.hasOwnProperty(key)) {
          let value = values[key];
          if (value) {
            q += key + "=" + value + "&";
          }
        }
      }
      setUrl(
        q.length > 0
          ? "/candidate/search-supply?" + q.slice(0, -1)
          : "/candidate/search-supply"
      );
    } catch (error: any) {
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ACTIVITY_SECTOR = await http.get(
          `simple-entity/findDropdown/ACTIVITY_SECTOR`
        );
        setSectors(ACTIVITY_SECTOR.data);
        const response = await http.get(`simple-entity/findDropdown/PROVINCE`);
        setDropdown_province(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [url]);

  const handleSectorChange = (event: any) => {
    setSelectedSector(event.target.value);
  };

  const handleProvinceChange = (event: any) => {
    setSelectedProvince(event.target.value);
  };

  const handleNameChange = (event: any) => {
    setSelectedName(event.target.value);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCandidate(null);
  };

  return (
    <React.Fragment>
      <OlogaPageHeader title={"Mapeamento de Oferta"}></OlogaPageHeader>
      <Grid container>
        <Grid item xs={12}>
          <Formik initialValues={{}} enableReinitialize onSubmit={handleSubmit}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4} mb={3}>
                  <Grid item xs={12} md={10}>
                    <OlogaTextfield name="candidateName" label="Pesquisa" />
                  </Grid>
                  {/*                   <Grid item xs={12} md={3}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel>Sector de Actividade</InputLabel>
                      <Select
                        value={selectedSector}
                        onChange={handleSectorChange}
                        label="Sector de Actividade"
                      >
                        <MenuItem value="">
                          <em>Selecione um setor</em>
                        </MenuItem>{" "}
                        {sectors.map((sector) => (
                          <MenuItem key={sector.value} value={sector.text}>
                            {sector.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel>Província</InputLabel>
                      <Select
                        value={selectedProvince}
                        onChange={handleProvinceChange}
                        label="Província"
                      >
                        <MenuItem value="">
                          <em>Selecione uma província</em>
                        </MenuItem>
                        {dropdown_province.map((province) => (
                          <MenuItem key={province.value} value={province.text}>
                            {province.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} md={2}>
                    <OlogaButton
                      type="submit"
                      icon={<SearchIcon />}
                      label="Pesquisar"
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={12}>
          <OlogaDataGrid columns={columns} url={url} />
        </Grid>
      </Grid>
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md">
        <DialogTitle>Detalhes do Candidato</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Nome do Candidato</TableCell>
                  <TableCell>
                    <strong>{selectedCandidate?.candidateName}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Província</TableCell>
                  <TableCell>
                    <strong>{selectedCandidate?.province}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Distrito</TableCell>
                  <TableCell>
                    <strong>{selectedCandidate?.district}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Categoria</TableCell>
                  <TableCell>
                    <strong>{selectedCandidate?.category}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Dimensão</TableCell>
                  <TableCell>
                    <strong>{selectedCandidate?.size}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sector de Actividade</TableCell>
                  <TableCell>
                    <strong>{selectedCandidate?.activitySector}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tipo de Assistência</TableCell>
                  <TableCell>
                    <strong>{selectedCandidate?.desiredAssistance}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Classificação da Assistência</TableCell>
                  <TableCell>
                    <strong>
                      {selectedCandidate?.estimatedAnnualJobCreation}
                    </strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Finalidade da Assistência</TableCell>
                  <TableCell>
                    <strong>{selectedCandidate?.assistancePurpose}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Anteriormente Beneficiado</TableCell>
                  <TableCell>
                    <strong>{selectedCandidate?.previouslyBenefited}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ToolSupplyMapping;
