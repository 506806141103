import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Card as MuiCard,
  Grid,
  Button,
  Modal,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { Box, spacing } from "@mui/system";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import SearchIcon from "@mui/icons-material/Search";
import http from "../../utils/axios";
import { toast } from "react-toastify";
import { Formik } from "formik";
import IssueRiskLogOSSForm from "./IssueRiskLogOSSForm";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface dropdownDTO {
  text: string;
  value: number;
}

const IssueRiskLogOSS: React.FC = () => {
  const Card = styled(MuiCard)(spacing);
  const navigate = useNavigate();

  const [candidate, setCandidate] = useState({});
  const [uri, setUri] = useState("");
  const [url, setUrl] = useState("/candidate");
  const [selectedCandidateId, setSelectedCandidateId] = useState<string | "">(
    ""
  );

  const [selectedProvince, setSelectedProvince] = useState<string | "">("");
  const [selectedSector, setSelectedSector] = useState<string | "">("");
  const [sectors, setSectors] = useState<dropdownDTO[]>([]);
  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const [selectedName, setSelectedName] = useState<string | "">("");
  const { state } = useLocation();

  const logColumns: GridColDef[] = [
    {
      field: "issueDescription",
      headerName: "Descrição",
      flex: 3,
      editable: false,
      renderCell: (params) => (
        <a
          onClick={() => {
            console.log(`/gap-assessment/candidate/${params.id}`);
            setUri(`/project-log/type/ISSUE_RISK_OSS`);
            navigate(`../issue-oss-form`, {
              state: {
                uri: `/project-log/type/ISSUE_RISK_OSS`,
                comments: params.row.comments ?? "",
                issueDescription: params.row.issueDescription ?? "",
                beneficiaryName: params.row.beneficiaryName ?? "",
                potentialImpact: params.row.potentialImpact ?? "",
                responsibleIdentification:
                  params.row.responsibleIdentification ?? "",
                responsibleResolution: params.row.responsibleResolution ?? "",
                resolutionDueDate: params.row.resolutionDueDate ?? "",
                escalationDate: params.row.escalationDate ?? "",
                resolutionDate: params.row.resolutionDate ?? "",
                status: params.row.status ?? "",
                id: params.id,
              },
            });
          }}
          style={{
            textDecoration: "none",
            color: "#ec1c24", // Optional: Adjust color for visibility
          }}
        >
          {params.row.issueDescription}
        </a>
      ),
    },
    {
      field: "potentialImpact",
      headerName: "Potential Impacto",
      flex: 3,
      editable: false,
    },
    {
      field: "resolutionDueDate",
      headerName: "Data Prevista de Resolução",
      flex: 3,
      editable: false,
    },
    {
      field: "escalationDate",
      headerName: "Data de Escalamento",
      flex: 3,
      editable: false,
    },
    {
      field: "resolutionDate",
      headerName: "Data de Resolução",
      flex: 3,
      editable: false,
    },
    {
      field: "status",
      headerName: "Estado",
      flex: 2,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 3.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            console.log(`/gap-assessment/candidate/${params.id}`);
            setUri(`/project-log/type/ISSUE_RISK_OSS`);
            navigate(`../issue-oss-form`, {
              state: {
                uri: `/project-log/type/ISSUE_RISK_OSS`,
                comments: params.row.comments ?? "",
                issueDescription: params.row.issueDescription ?? "",
                beneficiaryName: params.row.beneficiaryName ?? "",
                potentialImpact: params.row.potentialImpact ?? "",
                responsibleIdentification:
                  params.row.responsibleIdentification ?? "",
                responsibleResolution: params.row.responsibleResolution ?? "",
                resolutionDueDate: params.row.resolutionDueDate ?? "",
                escalationDate: params.row.escalationDate ?? "",
                resolutionDate: params.row.resolutionDate ?? "",
                status: params.row.status ?? "",
                id: params.id,
              },
            });
          }}
        >
          Editar
        </Button>,
      ],
    },
  ];

  const handleSubmit = async (values: any) => {
    try {
      if (selectedName != "" || selectedName.length > 0) {
        values["candidateName"] = selectedName;
      }
      if (selectedSector != "" || selectedSector.length > 0) {
        values["activitySector"] = selectedSector;
      }
      if (selectedProvince != "" || selectedProvince.length > 0) {
        values["province"] = selectedProvince;
      }
      console.log(values);
      let q = "";

      for (let key in values) {
        if (values.hasOwnProperty(key)) {
          let value = values[key];
          if (value) {
            q += key + "=" + value + "&";
          }
        }
      }
      console.log("query", q);
      setUrl(
        q.length > 0
          ? "/candidate/search-term/q?" + q.slice(0, -1)
          : "/candidate"
      );
    } catch (error: any) {
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ACTIVITY_SECTOR = await http.get(
          `simple-entity/findDropdown/ACTIVITY_SECTOR`
        );
        setSectors(ACTIVITY_SECTOR.data);

        const response = await http.get(`simple-entity/findDropdown/PROVINCE`);
        setDropdown_province(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [url]);

  const handleSectorChange = (event: any) => {
    console.log(event.target.value);

    setSelectedSector(event.target.value);
  };

  const handleProvinceChange = (event: any) => {
    console.log(event.target.value);

    setSelectedProvince(event.target.value);
  };
  const handleNameChange = (event: any) => {
    console.log(event.target.value);

    setSelectedName(event.target.value);
  };
  const handleOpenForm = () => {
    navigate(`../issue-oss-form`, {
      state: {
        uri: `/project-log/type/ISSUE_RISK_OSS`,
      },
    });
  };
  return (
    <React.Fragment>
      <OlogaPageHeader
        title={"Log de Riscos e Preocupações da OSS"}
        buttonLabel="Adicionar"
        onClick={handleOpenForm}
      ></OlogaPageHeader>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid
              url={"/project-log/type/ISSUE_RISK_OSS"}
              columns={logColumns}
            />
          </Card>
          <OlogaBackButton />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default IssueRiskLogOSS;
