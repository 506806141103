import React, { useEffect, useState } from "react";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Card as MuiCard,
  Grid,
  CardContent,
  Button,
  Modal,
  Typography,
  Stack,
  Skeleton,
  Chip,
  Divider,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import OlogaDataTableNormal from "../../components/tables/OlogaDataTableNormal";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import http from "../../utils/axios";
import Box from "@mui/material/Box";
import BackButton from "../../components/Buttons/BackButton";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { Formik } from "formik";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import OlogaSelect from "../../components/select/OlogaSelect";
import { toast } from "react-toastify";
import Item from "../../components/Combos/Item";
import OlogaGenericCombo from "../../components/Combos/OlogaGenericCombo";
import { FeedbackDetail } from "../form_builder/data_mapping";
import useAuth from "../../hooks/useAuth";

const Card = styled(MuiCard)(spacing);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AssessmentsFeedbackPage: React.FC = () => {
  const { state } = useLocation();
  const Card = styled(MuiCard)(spacing);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [assessmentId, setAssessmentId] = useState(0);
  const [feedId, setFeedId] = useState();
  const [feedbackDetails, setFeedbackDetails] = useState<FeedbackDetail[]>([]);
  const [feedName, setFeedName] = useState();

  const { user } = useAuth();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Assessment Evaluation Name",
      flex: 1,
      editable: false,
    },
    {
      field: "description",
      headerName: "Tool Name",
      flex: 1,
      editable: false,
    },

    {
      field: "actions",
      headerName: "Ações",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            const feedbackDetailsDTO = params?.row
              ?.feedbackDeatailDTOs as FeedbackDetail[];
            if (feedbackDetailsDTO.length > 0) {
              setFeedbackDetails(
                params?.row?.feedbackDeatailDTOs as FeedbackDetail[]
              );
              setAssessmentId(params?.row?.id);
              setFeedName(params?.row?.name);
              handleOpen();
            }
          }}
          label="Edit"
        />,
      ],
    },
  ];

  const handleSubmitFeedBack = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    const feedback = {
      name: feedName,
      assessmentScore: { id: assessmentId },
      assessmentScoreStatus: 2,
      ...values,
    };
    try {
      await http.post(`/assessments/feedback`, feedback);
      resetForm();
      handleClose();
      toast.success("Operação realizada com sucesso");
    } catch (error) {
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    }
  };

  return (
    <>
      <React.Fragment>
        <OlogaPageHeader
          title={"Página de Feedback"}
          //   buttonLabel="Fill Tool"
          //   onClick={() => {
          //     navigate(`/tools/form`, {
          //       state: {
          //         toolId: state.toolId,
          //         assessmentId: state.assessmentId,
          //         candidateId: state?.candidateId,
          //         toolDesc: state?.name,
          //         toolCode: state?.description,
          //       },
          //     });
          //   }}
        ></OlogaPageHeader>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <OlogaDataGrid
                url={"/assessments/feedback/evaluation"}
                columns={columns}
              />
            </Card>
          </Grid>
          <CardContent>
            <div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Formik initialValues={{}} onSubmit={handleSubmitFeedBack}>
                    {({ handleSubmit, status, errors }) => (
                      <Card>
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            Feedback Detail
                          </Typography>
                          <form onSubmit={handleSubmit}>
                            {feedbackDetails.map((feedback: FeedbackDetail) => {
                              return (
                                <>
                                  {user?.firstName === feedback.userLetter ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Stack spacing={1}>
                                        <Stack direction="row" spacing={1}>
                                          <Chip
                                            label={`${feedback.roleName} : ${feedback.userLetter}`}
                                            size="medium"
                                            variant="outlined"
                                          />
                                        </Stack>
                                        <Stack direction="column" spacing={1}>
                                          <Box sx={{ width: 100 }}>
                                            <Chip
                                              sx={{
                                                height: "auto",
                                                "& .MuiChip-label": {
                                                  display: "block",
                                                  whiteSpace: "normal",
                                                },
                                                width: "auto",
                                              }}
                                              label={feedback.body}
                                            />
                                          </Box>
                                        </Stack>
                                        <Divider />
                                        <br />
                                      </Stack>
                                    </div>
                                  ) : (
                                    <div>
                                      <Stack spacing={1}>
                                        <Stack direction="row" spacing={1}>
                                          <Chip
                                            label={`${feedback.roleName} : ${feedback.userLetter}`}
                                            size="medium"
                                            variant="outlined"
                                          />
                                        </Stack>
                                        <Stack direction="column" spacing={1}>
                                          <Box sx={{ width: 100 }}>
                                            <Chip
                                              sx={{
                                                height: "auto",
                                                "& .MuiChip-label": {
                                                  display: "block",
                                                  whiteSpace: "normal",
                                                },
                                                width: "auto",
                                              }}
                                              label={feedback.body}
                                            />
                                          </Box>
                                        </Stack>
                                        <Divider />
                                        <br />
                                      </Stack>
                                    </div>
                                  )}
                                </>
                              );
                            })}

                            <Grid container spacing={3}>
                              {/* <Grid item xs={12}>
                                <OlogaTextfield name="name" label="Name" />
                              </Grid> */}
                              <Grid item xs={12}>
                                <OlogaTextfield
                                  name="description"
                                  label="Detailed Feed Back"
                                  multiline
                                  rows={5}
                                />
                              </Grid>
                            </Grid>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={() => {
                                  handleSubmit();
                                  handleClose();
                                }}
                                style={{ marginBottom: "10px" }}
                              >
                                Submit Feedback
                              </Button>
                            </div>
                          </form>
                        </CardContent>
                      </Card>
                    )}
                  </Formik>
                </Box>
              </Modal>
            </div>
          </CardContent>
        </Grid>
      </React.Fragment>
      <BackButton />
    </>
  );
};

export default AssessmentsFeedbackPage;
