import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Button,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import http from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";

ChartJS.register(ArcElement, Tooltip, Legend);

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 16,
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  background: theme.palette.background.default,
}));

const ChartContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "300px",
  width: "100%",
});

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.primary.main,
  textAlign: "center",
  marginBottom: theme.spacing(2),
}));

const Panel: React.FC = () => {
  const [candidateCount, setCandidateCount] = useState(0);
  const [candidateCompleted, setCandidateCompleted] = useState(0);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [_dataCandidaturasPorEstado, setDataCandidaturasPorEstado] =
    useState<any>({
      labels: [],
      datasets: [],
    });
  const [_dataCandidaturasPorTipo, setDataCandidaturasPorTipo] = useState<any>({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        var userId = user?.id;
        if (user?.roleName !== "ROLE_CANDIDATE") {
          userId = 0;
        }

        const response_ = await http.get(`/candidate/list`);
        setCandidateCount(response_.data.length);

        const response_candidateCompleted = await http.get(
          `/candidate/inComplete`
        );
        setCandidateCompleted(response_candidateCompleted.data);

        const response = await http.get(`/candidate/data/` + userId);
        const { estado, tipo } = response.data;

        setDataCandidaturasPorEstado({
          labels: estado.labels,
          datasets: [
            {
              label: "Candidaturas por Estado",
              data: estado.data,
              backgroundColor: estado.backgroundColor,
              borderColor: estado.borderColor,
              borderWidth: 1,
            },
          ],
        });

        setDataCandidaturasPorTipo({
          labels: tipo.labels,
          datasets: [
            {
              label: "Candidaturas por Tipo",
              data: tipo.data,
              backgroundColor: tipo.backgroundColor,
              borderColor: tipo.borderColor,
              borderWidth: 1,
            },
          ],
        });
      } catch (error) {
        console.error("Erro ao buscar dados da API:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Grid container spacing={4} style={{ marginTop: 16, marginBottom: 16 }}>
        {/* Candidaturas por Estado */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <SectionTitle variant="h6">Candidaturas por Estado</SectionTitle>
              <Divider style={{ marginBottom: "16px" }} />
              <ChartContainer>
                <Pie data={_dataCandidaturasPorEstado} />
              </ChartContainer>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Candidaturas por Tipo */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <SectionTitle variant="h6">Candidaturas por Tipo</SectionTitle>
              <Divider style={{ marginBottom: "16px" }} />
              <ChartContainer>
                <Pie data={_dataCandidaturasPorTipo} />
              </ChartContainer>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </>
  );
};

export default Panel;
