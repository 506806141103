import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import http from "../../utils/axios";
import { Grid, alpha, styled } from "@mui/material";
import { set } from "date-fns";
import OlogaDownloadExcel from "../misc/OlogaDownloadExcel";
import { cleanHeanders } from "../../utils/utils";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}`]: {
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.background.default,
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
    },
  },
  [`.MuiDataGrid-columnHeadersInner div`]: {
    backgroundColor: "#000",
    color: "#FFF",
  },
}));

interface OlogaDataGridProps {
  url?: string;
  columns: any;
  showCheckbox?: boolean;
  isPagedContent?: boolean;
  paginationMode?: "client" | "server";
  existingData?: any;
  triggerUseEffect?: any;
  renderOnModal?: boolean;
  pageSize?: number;
  exportToExcel?: boolean;
  fileName?: string;
  buttonLabel?: string;
  excludeFromExport?: string[];
}

function OlogaDataGrid({
  url,
  columns,
  showCheckbox = false,
  isPagedContent = true,
  paginationMode = "server",
  existingData,
  triggerUseEffect,
  renderOnModal = false,
  pageSize = 25,
  exportToExcel = false,
  fileName = "data_export",
  buttonLabel = "Export to Excel",
  excludeFromExport = [],
}: OlogaDataGridProps) {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: pageSize,
    page: 0,
  });
  useEffect(() => {
    const getData = async () => {
      try {
        let joinChar = "?";
        if (url?.includes("?")) {
          joinChar = "&";
        }
        const response = await http.get(
          `${url}${joinChar}size=${paginationModel.pageSize}&page=${paginationModel.page}`
        );

        if (isPagedContent) {
          const responseAll = await http.get(
            `${url}${joinChar}size=${response.data.totalElements}&page=${paginationModel.page}`
          );
          setAllData(responseAll.data.content);
        }

        setData(isPagedContent ? response.data.content : response.data);

        setTotalElements(
          isPagedContent ? response.data.totalElements : response.data.length
        );
      } catch (error) {}
    };
    if (existingData) {
      setData(existingData);
      setTotalElements(existingData.length);
    } else {
      if (url) {
        getData();
      }
    }
    console.log("xpto:", paginationModel);
  }, [url, paginationModel, existingData, triggerUseEffect]);

  return (
    <>
      {exportToExcel && data.length > 0 && (
        <Grid container justifyContent="flex-end" my={2} px={2}>
          <OlogaDownloadExcel
            data={data}
            headers={cleanHeanders(columns, excludeFromExport)}
            buttonLabel="Export current page"
            fileName={fileName}
          />
          <OlogaDownloadExcel
            data={allData}
            headers={cleanHeanders(columns, excludeFromExport)}
            buttonLabel="Export All"
            fileName={fileName}
          />
        </Grid>
      )}

      <StripedDataGrid
        autoHeight
        rows={data}
        columns={columns}
        rowCount={totalElements}
        pageSizeOptions={[5, 10, 25, 50, 75, 100]}
        paginationMode={paginationMode}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        checkboxSelection={showCheckbox}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
      />
    </>
  );
}

export default OlogaDataGrid;
