import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const Terms: React.FC = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const handleAccept = () => {
    setOpen(false);
  };

  const handleReject = () => {
    navigate(-1);
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>Termos e Condições Gerais de Uso</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ maxHeight: "80%", overflowY: "auto", textAlign: "justify" }}>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            Termos e Condições Gerais de Uso (GTCU)
          </Typography>
          <Typography variant="body1" paragraph>
            Bem-vindo à (página da Ferramenta de Gestão do CapacitaMoz
            http://www.) (doravante a «Página»). Ao aceder concorda em ler e
            aceitar, sem limitação ou reserva, este documento (doravante os
            “Termos e Condições Gerais de Uso” ou os “GTCU”) clicando a caixa
            providenciada para o efeito quando se conecta pela primeira vez à
            Página. Igualmente, compromete-se a ler a carta de dados e cookies
            (Insira o hiperlink para a carta de dados pessoais e cookies da
            Página) disponível nesta Página. Note que ao visitar outras páginas
            do grupo TotalEnergies, diferentes termos e condições gerais de uso
            e políticas relacionadas à protecção de dados pessoais, ou qualquer
            outro documento relacionado à protecção de dados pessoais, se
            aplicam a essas páginas. Recomenda-se que leia atentamente os
            respectivos avisos legais.
          </Typography>
          <Typography variant="body1" paragraph>
            As empresas do grupo TotalEnergies possuem existência legal própria
            e estatuto legal independente. A empresa que publica esta Página
            (doravante “Editora da Página”) pertence ao grupo TotalEnergies.
            “TotalEnergies”, “grupo TotalEnergies”, “Grupo” e “Empresa”
            geralmente se referem à TotalEnergies SE e/ou suas subsidiárias e
            afiliadas; uma subsidiária é uma empresa na qual a TotalEnergies SE
            detém directa ou indirectamente a maioria dos direitos de voto. Da
            mesma forma, as palavras “nós”, “nos” e “nosso” são usadas para se
            referir à Editora da Página ou a qualquer empresa da empresa
            TotalEnergies em geral ou àquelas que trabalham para esta. Essas
            expressões são igualmente usadas quando não há qualquer interesse na
            identificação da empresa ou empresas em particular. Não se pode
            inferir dessas expressões que a TotalEnergies SE ou qualquer uma de
            suas subsidiárias ou afiliadas esteja envolvida nos negócios e na
            gestão de qualquer outra empresa da empresa TotalEnergies.
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            1. Identidade
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            1.1. Editora da Página
          </Typography>
          <Typography variant="body1" paragraph>
            TotalEnergies EP Mozambique Area 1, Lda. Sede: Avenida Julius
            Nyerere n.° 3412, Maputo, Moçambique Tipo de empresa: sociedade de
            responsabilidade limitada Registo Comercial e de Empresas:
            Conservatória do Registo de Entidades Legais de Maputo Sob o Número
            100004674 NUIT: 400162621
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            1.2. Empresa de Hospedagem da Página XXXX
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            2. Definições
          </Typography>
          <Typography variant="body1" paragraph>
            Os termos e expressões identificados por letras maiúsculas nos GTCU
            têm os seguintes significados, sejam usados no singular ou no
            plural:
            <p>
              <strong>“Conta”:</strong> refere-se ao espaço único reservado ao
              Usuário, acessível ao final do registo e que permite que o Usuário
              use os serviços oferecidos na Página.
            </p>
            <p>
              {" "}
              <strong>“Contribuição”:</strong> refere-se a qualquer tipo de
              conteúdo (incluindo texto e documentos) publicado por um Usuário
              na Página.
            </p>
            <p>
              {" "}
              <strong>“Perfil”:</strong> refere-se à interface disponibilizada a
              um Usuário e acessível a outros Usuários, incluindo a descrição
              feita pelo Usuário e textos e documentos.
            </p>
            <p>
              {" "}
              “Usuário”: refere-se a qualquer pessoa que tenha uma Conta e aceda
              a Página.
            </p>
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            3. Acesso e registo
          </Typography>
          <Typography variant="body1" paragraph>
            Os GTCU foram elaborados para regular o uso da Página. No campo da
            tecnologia e da comunicação, estes complementam as cartas e outros
            textos aplicáveis na Empresa: Código de conduta, regulamentos
            internos, carta para o uso de recursos de TI, etc. Qualquer Usuário
            da Página deve respeitar os princípios estabelecidos pelos GTCU que
            definem um quadro geral e comum para todos para evitar o uso
            inapropriado da Página. Estes se aplicam a todas as funcionalidades
            da Página
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            3.1 Condições de acesso à Página
          </Typography>
          <Typography variant="body1" paragraph>
            Cada Usuário reconhece cumprir com os GTCU e as leis em vigor, e em
            particular:
            <ul>
              <li>
                {" "}
                ter as habilidades e os meios necessários para aceder e usar a
                Página;
              </li>
              <li>
                {" "}
                consentir à Editora da Página e seus parceiros, conforme o caso,
                o direito de fazer qualquer uso das informações providenciadas
                (excepto os dados pessoais);
              </li>
              <li>
                {" "}
                verificar se a configuração da Internet usada não contém vírus e
                está em perfeitas condições de funcionamento;
              </li>

              <li>
                manter confidenciais os detalhes de acesso e senhas que a
                Editora da Página pode passar ao Usuário e, dessarte, ser
                responsável por seu uso e segurança. A Editora da Página
                reserva-se o direito de suspender o acesso à Página em caso de
                uso fraudulento ou tentativa de uso fraudulento de tal acesso.
              </li>
            </ul>
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            3.2 Procedimento de Registo na Página
          </Typography>
          <Typography variant="body1" paragraph>
            Para usar a Página, o Utilizador deverá criar uma Conta fornecendo a
            informação solicitada. O Utilizador receberá um código de
            confirmação certificando o seu registo e permitindo-lhe aceder e
            utilizar a Plataforma. O Utilizador deverá escolher uma
            palavra-passe suficientemente segura. Uma vez registado, o
            Utilizador poderá aceder a sua Conta com as credenciais fornecidas
            durante o registo.
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            4. Utilização e Compromissos do Utilizador
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            4.1 Uso da Página
          </Typography>
          <Typography variant="body1" paragraph>
            Na Página, o Utilizador terá a possibilidade de:
            <ul>
              <li>Criar uma conta</li>
              <li>Actualizar o seu perfil</li>
              <li>Receber a assistência solicitada</li>
            </ul>
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            4.2 Compromissos do Utilizador
          </Typography>
          <Typography variant="body1" paragraph>
            O Utilizador reconhece que:
            <ul>
              <li>
                os GTCU aplicam-se a qualquer Utilizador autorizado a usar a
                Página;
              </li>
              <li>a Página destina-se a uso profissional;</li>

              <li>
                cada Utilizador é identificado e fala em seu nome na Página.
              </li>
            </ul>
            O Utilizador compromete-se ainda a:
            <ul>
              <li>
                proteger os dados pessoais de acordo com a legislação aplicável
                e as normas internas. Abster-se-á de qualquer utilização que
                seja contrária às normas em vigor, à ordem pública e aos bons
                costumes;
              </li>
              <li>
                fornecer informações precisas, completas, sinceras e inequívocas
                e garantir, se necessário, que são actualizadas em caso de
                alterações;
              </li>

              <li>
                ter todos os direitos e autorizações necessárias para fornecer
                as informações e conteúdos publicados.
              </li>
            </ul>
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            5. Propriedade intelectual
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            5.1. Princípio
          </Typography>
          <Typography variant="body1" paragraph>
            Toda a informação ou documentos (textos, imagens animadas ou
            estáticas, sons, fotografias, conhecimento, produtos citados)
            exibidos na Página, bem como todos os elementos criados para a
            Página e a sua estrutura geral, são propriedade da Editora da Página
            ou do grupo TotalEnergies, ou estão sujeitos a direitos de uso,
            reprodução e representação que lhe tenham sido concedidos. Estas
            informações, documentos e elementos estão sujeitos a leis que
            protegem os direitos de autor, na medida em que tenham sido
            disponibilizados ao público nesta Página. Nenhuma licença ou
            qualquer direito para além da visualização da Página foi concedido a
            qualquer pessoa no que respeita aos direitos de propriedade
            intelectual. A reprodução dos documentos da Página é autorizada
            apenas como informação para uso pessoal e privado. Qualquer
            reprodução e qualquer uso de cópias efectuadas para outros fins é
            expressamente proibida e sujeita a autorização prévia e expressa da
            Editora da Página. Em todos os casos, a reprodução autorizada da
            informação apresentada na Página deverá citar a fonte e a
            propriedade adequadas.
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            5.2. Marcas Distintivas
          </Typography>
          <Typography variant="body1" paragraph>
            Salvo indicação em contrário, os nomes de empresas, logotipos,
            produtos e marcas citados na Página são propriedade da Editora da
            Página ou da empresa TotalEnergies, ou estão sujeitos a direitos de
            uso, reprodução ou representação que tenham sido concedidos em seu
            benefício. Não podem ser usados sem o consentimento prévio por
            escrito da Editora da Página.
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            5.3. Base de dados
          </Typography>
          <Typography variant="body1" paragraph>
            Quaisquer bases de dados que lhe sejam disponibilizadas são
            propriedade da Editora da Página, que tem o estatuto de produtora de
            bases de dados. Está proibido de extrair ou reutilizar qualquer
            parte qualitativa ou quantitativamente substancial das bases de
            dados, incluindo para fins privados.
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            6. Hyperlinks
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            6.1. Activação de links
          </Typography>
          <Typography variant="body1" paragraph>
            A Editora da Página não assume formalmente qualquer responsabilidade
            pelo conteúdo das Páginas que fornece. Consulte, por favor, as
            condições gerais de utilização e a política de dados pessoais e
            cookies, ou qualquer outro documento relativo à protecção de dados
            pessoais, destas Páginas para compreender as suas práticas. A
            decisão de activar os links é tomada exclusivamente pelos
            Utilizadores. A Editora da Página pode modificar ou eliminar um link
            da Página a qualquer momento.
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            6.2. Autorização de links
          </Typography>
          <Typography variant="body1" paragraph>
            Se desejar criar um link de hipertexto para esta Página, deverá
            obter o consentimento prévio por escrito da Editora da Página,
            utilizando os dados de contacto mencionados no final dos GTCU.
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            7. Aviso Formal
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            7.1. Responsabilidade
          </Typography>
          <Typography variant="body1" paragraph>
            As informações e recomendações disponíveis nesta Página (doravante
            “Informações”) são fornecidas de boa-fé. Supõe-se que estão
            correctos no momento da sua publicação na Página. No entanto, a
            Editora da Página não representa nem garante a integralidade e
            exactidão da Informação. Assume integralmente os riscos associados
            ao crédito que lhes concede. As Informações são-lhe fornecidas com a
            condição de que o utilizador ou qualquer outra pessoa que as receba
            possa determinar o seu interesse para uma finalidade específica
            antes de as utilizar. Em caso algum a Editora da Página será
            responsável por quaisquer danos que possam resultar da confiança ou
            utilização da Informação. As Informações não devem ser consideradas
            como recomendações para a utilização de informações, produtos,
            procedimentos, equipamentos ou formulações que estariam em
            contradição com qualquer direito de propriedade intelectual. A
            Editora da Página não assume qualquer responsabilidade, expressa ou
            implícita, se a utilização da Informação violar um direito de
            propriedade intelectual. A Editora da Página, e qualquer empresa da
            TotalEnergies, rejeita categoricamente qualquer interpretação que
            possa associar o conteúdo das suas Páginas com ofertas de compra ou
            incentivos para adquirir acções ou outros valores mobiliários
            negociáveis, listados ou não, da Editora da Página ou de qualquer
            outra empresa da TotalEnergies. Não é dada qualquer garantia,
            expressa ou implícita, em relação à natureza comercial das
            Informações fornecidas, nem quanto à sua adequação a um fim
            específico, nem quanto aos produtos referidos em tais Informações.
            Em caso algum a Editora da Página se compromete a actualizar ou
            corrigir as Informações que serão publicadas na Internet ou nos seus
            servidores de internet. A Editora da Página reserva-se o direito de
            alterar ou corrigir o conteúdo das suas Páginas a qualquer momento
            sem aviso prévio. A Editora da Página não garante, sem que esta
            lista seja exaustiva, que a Página funcione ininterruptamente e que
            os servidores que lhe dão acesso e/ou páginas de terceiros para os
            quais se referem os links de hipertexto não contenham vírus.
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            7.2. Declarações Prospectivas
          </Typography>
          <Typography variant="body1" paragraph>
            Os documentos apresentados nesta página podem conter declarações
            prospectivas sobre o Grupo (incluindo objectivos e tendências) e
            declarações prospectivas na acepção da Lei de Reforma de Litígios de
            Valores Mobiliários Privados de 1995, incluindo declarações sobre a
            situação financeira da TotalEnergies, resultados de operações,
            negócios e estratégia. As declarações prospectivas contidas nestes
            documentos baseiam-se em dados económicos e premissas feitas num
            determinado ambiente económico, competitivo e regulamentar. Podem
            revelar-se imprecisos no futuro e dependem de factores de risco que
            podem fazer com que os resultados reais sejam materialmente
            diferentes daqueles contemplados, tais como, mas não limitados a,
            alterações nas taxas de câmbio, no preço dos produtos petrolíferos,
            na capacidade de obter reduções de custos ou ganhos de eficiência
            sem sobressaltos indevidos nas operações, considerações
            regulamentares ambientais e condições económicas e financeiras
            gerais. Da mesma forma, alguma informação financeira baseia-se em
            estimativas, nomeadamente na avaliação do valor recuperável dos
            activos e dos montantes de eventuais perdas por imparidade. Nem a
            TotalEnergies nem qualquer das suas subsidiárias assumem qualquer
            obrigação de actualizar ou rever, especialmente como resultado de
            novas informações e/ou eventos futuros, qualquer ou todas as
            declarações, declarações prospectivas, tendências ou objectivos
            contidos nestes documentos. Informações adicionais sobre factores
            riscos e incertezas que podem afectar os resultados financeiros ou
            negócios da Sociedade estão igualmente disponíveis nas versões mais
            actualizadas do Documento de Referência/ Documento de Registo
            Universal arquivado na França junto da Autoridade dos Mercados
            Financeiros e o relatório anual no Formulário 20-F apresentado à
            Comissão de Valores Mobiliários dos Estados Unidos (“SEC”).
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            8. Actualizações dos GTCU da Página e legislação aplicável
          </Typography>
          <Typography variant="body1" paragraph>
            A Editora de Página poderá actualizar os GTCU da Página a qualquer
            momento. Os utilizadores são convidados a consultar regularmente os
            GTCU mais recentes disponíveis na Página. Se uma ou mais disposições
            dos GTCU forem consideradas não aplicáveis ou proibidas por
            regulamentos de política pública, as outras disposições dos GTCU não
            afectados permanecerão aplicáveis aos Utilizadores na medida do
            permitido por tais regulamentos. Os GTCU estão sujeitos à lei
            francesa e são da jurisdição dos tribunais franceses competentes.
          </Typography>
          <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
            9. Contacto
          </Typography>
          <Typography variant="body1" paragraph>
            Para qualquer questão relacionada com os GTCU da Página, pode
            contactar-nos através do seguinte endereço: TotalEnergies EP
            Mozambique Area 1, Lda. Sede: Avenida Julius Nyerere n.° 3412,
            Maputo, Moçambique
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReject}>Rejeitar</Button>
        <Button onClick={handleAccept} color="primary">
          Aceitar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Terms;
