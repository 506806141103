import React, { useEffect, useState } from "react";
import { Grid, Card, Typography, Box } from "@mui/material";
import { Bar, Pie, Line, Doughnut, Radar } from "react-chartjs-2";
import http from "../utils/axios";

const Dashboard2 = () => {
  const [PerformanceIndicators, setPerformanceIndicators] = useState([
    {
      labels: [],
      type: "",
      datasets: [
        {
          label: "  ",
          data: [],
          backgroundColor: "",
        },
      ],
    },
  ]);

  const fetchData = async () => {
    try {
      const getPerformanceIndicators = await http.get(
        `dashboard/performanceIndicators`
      );
      console.log("getPerformanceIndicators", getPerformanceIndicators.data);
      setPerformanceIndicators(getPerformanceIndicators.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box style={{ padding: 20 }}>
      <Typography variant="h3" gutterBottom>
        Dashboard de Indicadores do CapacitaMoz
      </Typography>
      <Grid container spacing={3}>
        {PerformanceIndicators.map((indicator: any, index) => (
          <Grid item xs={4} key={index}>
            <Card style={{ padding: 20 }}>
              <Typography variant="h6">
                {indicator.datasets[0].label}
              </Typography>

              {indicator.type === "Gráfico de Barra" && (
                <Bar data={indicator} />
              )}
              {indicator.type === "Gráfico de pizza" && (
                <Pie data={indicator} />
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Dashboard2;
