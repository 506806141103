import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Grid } from "@mui/material";
import http from "../../utils/axios";
import OlogaButtonGroup from "../../components/Buttons/OlogaButtonGroup";
import { ToastContainer } from "react-toastify";
import {
  candidateUserSchema,
  passwordRules,
  userSchema,
} from "../../utils/formValidation";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import OlogaSelect from "../../components/select/OlogaSelect";
import useLookups from "../../hooks/useLookups";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import OlogaConfirmDialog from "../../components/misc/OlogaConfirmDialog";
import OlogaAlert from "../../components/misc/OlogaAlert";

interface UserFormProps {
  user: any;
}

const UserForm = ({ user }: UserFormProps) => {
  const { user: principal } = useAuth();
  const [isAdmin] = useState(principal?.roleName === "ROLE_ADMIN");
  const [isPromoter] = useState(principal?.roleName === "ROLE_PROMOTER");
  const [isVendor] = useState(
    principal?.roleName === "ROLE_VENDOR" ||
      principal?.roleName === "ROLE_VENDOR_SUPERVISOR"
  );
  const navigate = useNavigate();
  const { data: roles } = useLookups("/users/roles");
  const { data: vendors } = useLookups("/companies/vendors/names");
  const { data: promoters } = useLookups("/companies/promoters/names");
  const [companyLabel, setCompanyLabel] = useState(
    isVendor ? "Fornecedor" : "Fornecedor/Promotor"
  );
  const [companies, setCompanies] = useState(vendors);
  const [showCompanies, setShowCompanies] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleRoleChange = (value: any) => {
    setCompanies(value === 2 ? promoters : vendors);
    setCompanyLabel(value === 2 ? "Promotor" : "Fornecedor");
    setShowCompanies(value !== 1);
  };

  const handleSubmit = async (
    values: any,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      const url = user?.signup ? "/auth/signup/candidate" : "/users";
      console.log(url);
      console.log(user);

      if (user.id) {
        await http.put(url, values);
      } else {
        await http.post(
          url,
          user?.signup ? { ...values, rolename: "ROLE_CANDIDATE" } : values
        );
      }
      setStatus({ sent: true });
      setSubmitting(false);
      setOpenDialog(true);
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({
        submit: error.message || "Erro ao submeter o formulário",
      });
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user?.id || !isAdmin) {
        const url = isVendor
          ? "/companies/vendors/names"
          : user.roleCode === 2
          ? "/companies/promoters/names"
          : "/companies/vendors/names";
        setShowCompanies(user.roleCode !== 1);
        try {
          const result = await http.get(url);
          setCompanies(result.data);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, [user]);

  return (
    <>
      <Formik
        initialValues={{
          ...user,
          password: user?.textPassword,
          confirmPassword: user?.textPassword,
        }}
        validationSchema={
          user?.signup
            ? candidateUserSchema.concat(passwordRules)
            : userSchema.concat(passwordRules)
        }
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, status, handleSubmit, setSubmitting }) => (
          <>
            <form onSubmit={handleSubmit}>
              <ToastContainer position="top-right" />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <OlogaTextfield name="firstName" label="Nome" />
                </Grid>
                <Grid item xs={6}>
                  <OlogaTextfield name="lastName" label="Apelido" />
                </Grid>
                <Grid item xs={6}>
                  <OlogaTextfield name="username" label="Nome de Utilizador" />
                </Grid>
                <Grid item xs={6}>
                  <OlogaTextfield name="email" label="Email" />
                </Grid>
                <Grid item xs={6}>
                  <OlogaTextfield
                    name="password"
                    label="Palavra-passe"
                    type="password"
                  />
                </Grid>
                <Grid item xs={6}>
                  <OlogaTextfield
                    name="confirmPassword"
                    label="Confirmar Palavra-passe"
                    type="password"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  {user?.signup ? null : (
                    <OlogaSelect
                      name="roleCode"
                      label="Perfil"
                      options={roles}
                      setValue={handleRoleChange}
                    />
                  )}
                </Grid>
              </Grid>
              <OlogaButtonGroup />
            </form>
            <OlogaAlert
              title="Confirmação!"
              description={`O seu registo foi efectuado com sucesso. Agora pode aceder ao sistema com as credenciais introduzidas no acto do cadastro !`}
              open={openDialog}
              setOpen={setOpenDialog}
              handleConfirm={async () =>
                await navigate(user?.signup == true ? "/" : "/users")
              }
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default UserForm;
