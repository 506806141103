import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { OlogaButton } from "../Buttons/OlogaButton";
import { Box } from "@mui/material";

interface OlogaConfirmDialogProps {
  title: string;
  description: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  value?: any;
  handleConfirm?: (value: any, comment?: string) => void; // Make comment optional
  hasCommentBox?: boolean; // Flag to show/hide the comment box
}

function OlogaConfirmDialog({
  title,
  description,
  open,
  setOpen,
  value,
  handleConfirm,
  hasCommentBox = false,
}: OlogaConfirmDialogProps) {
  const [comment, setComment] = useState("");

  const handleClose = () => {
    setOpen(false);
    setComment(""); // Clear comment when closing
  };

  const handleConfirmClick = () => {
    if (handleConfirm) {
      handleConfirm(comment); // Pass only the comment back
    }
    handleClose();
  };

  useEffect(() => {
    if (!open) {
      setComment(""); // Reset comment if the dialog is closed
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
          {hasCommentBox && (
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Commentário"
              variant="outlined"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              margin="normal"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Box mr={2}>
            <OlogaButton
              label="Cancel"
              variant="outlined"
              mr={2}
              onClick={handleClose}
            />
            <OlogaButton label="Confirmar" onClick={handleConfirmClick} />
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OlogaConfirmDialog;
