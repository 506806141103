import React, { useState, useEffect } from "react";
import {
  Card as MuiCard,
  Grid,
  Button,
  CardContent,
  CircularProgress,
  Typography,
  Modal,
  Box,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import styled from "@emotion/styled";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { toast } from "react-toastify";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import { SelectChangeEvent } from "@mui/material";
import http from "../../utils/axios";
import AddQuestionModal from "../../components/modal/AddQuestionModal";

interface dropdownDTO {
  text: string;
  value: number;
}

interface IQuestion {
  id: number;
  description: string;
  name: string;
  categoryId: number;
  typeId: number;
  answers?: string[];
}

// Cartão estilizado a partir do MUI
const Card = styled(MuiCard)();

export default function AnswersAndQuestions() {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [openAnswersModal, setOpenAnswersModal] = useState(false); // Modal para respostas
  const [selectedQuestion, setSelectedQuestion] = useState<IQuestion | null>(
    null
  ); // Pergunta selecionada para respostas
  const [dropdown_tool, setDropdown_tool] = useState<dropdownDTO[]>([]);
  const [dropdown_type, setDropdown_type] = useState<dropdownDTO[]>([]);
  const [newQuestion, setNewQuestion] = useState<IQuestion>({
    id: 0,
    description: "",
    name: "",
    categoryId: 0,
    typeId: 0,
    answers: [],
  });
  const [newAnswers, setNewAnswers] = useState<string[]>([""]);

  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const QUESTION_TOOL = await http.get(`setting/questions-tool`);
      setDropdown_tool(QUESTION_TOOL.data);

      const QUESTION_TYPE = await http.get(
        `simple-entity/findDropdown/QUESTION_TYPE`
      );
      setDropdown_type(QUESTION_TYPE.data);

      const response = await http.get("setting/questions");

      const data = Array.isArray(response.data.content)
        ? response.data.content.map((item: any) => ({
            ...item,
            answers: item.answers || [],
          }))
        : [];

      setQuestions(data);
    } catch (error) {
      console.error("Erro ao buscar perguntas:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  const handleAddAnswer = () => {
    setNewAnswers([...newAnswers, ""]);
  };

  const handleRemoveAnswer = (index: number) => {
    const updatedAnswers = newAnswers.filter((_, i) => i !== index);
    setNewAnswers(updatedAnswers);
  };

  const handleAnswerChange = (index: number, value: string) => {
    const updatedAnswers = [...newAnswers];
    updatedAnswers[index] = value;
    setNewAnswers(updatedAnswers);
  };

  const handleSubmit = async () => {
    const questionPayload = {
      ...newQuestion,
      answers: newAnswers,
    };

    try {
      const result = await http.post(
        `questions/addQuestionSetting`,
        questionPayload
      );
      fetchQuestions();
      toast.success("Operação realizada com sucesso");
    } catch (error) {
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    } finally {
      setOpenModal(false);
    }
  };

  const handleSelectChange =
    (fieldName: keyof typeof newQuestion) =>
    (event: SelectChangeEvent<unknown>) => {
      const { value } = event.target;
      setNewQuestion({ ...newQuestion, [fieldName]: value });
    };

  const handleOpenAnswersModal = (question: IQuestion) => {
    setSelectedQuestion(question);
    setOpenAnswersModal(true);
  };

  const handleModalSuccess = () => {
    fetchQuestions();
  };

  return (
    <>
      <OlogaPageHeader
        title={"Configurações: Adicionar Perguntas e Respostas"}
        buttonLabel="Adicionar Perguntas e Respostas"
        onClick={() => setOpenModal(true)}
      ></OlogaPageHeader>

      <AddQuestionModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSuccess={handleModalSuccess}
      />

      <Modal open={openAnswersModal} onClose={() => setOpenAnswersModal(false)}>
        <Box sx={{ width: 600, bgcolor: "white", p: 4, mx: "auto", my: "10%" }}>
          <Typography variant="h6">
            Respostas para {selectedQuestion?.name}
          </Typography>
          <Typography variant="subtitle1">
            {selectedQuestion?.description}
          </Typography>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Resposta</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedQuestion?.answers?.map((answer, index) => (
                  <TableRow key={index}>
                    <TableCell>{answer}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>

      <Card>
        <CardContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome da Pergunta</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {questions.map((question) => (
                    <TableRow key={question.id}>
                      <TableCell>{question.name}</TableCell>
                      <TableCell>{question.description}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          onClick={() => handleOpenAnswersModal(question)}
                        >
                          Ver Respostas
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>

      <Card sx={{ mt: 2 }}>
        <CardContent>
          <OlogaBackButton />
        </CardContent>
      </Card>
    </>
  );
}
