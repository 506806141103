import React from "react";
import { TextField as MuiTextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

export type OlogaTextfieldProps = {
  name: string;
} & TextFieldProps;

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const OlogaTextfield: React.FC<OlogaTextfieldProps> = ({
  name,
  ...otherProps
}) => {
  const [field, meta] = useField(name);

  const configTextfield: TextFieldProps = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  return <TextField {...configTextfield} my={4} />;
};

export default OlogaTextfield;
