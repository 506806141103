import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  Card as MuiCard,
  Grid,
  Modal,
  Typography,
  Button,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { Box, spacing } from "@mui/system";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import ProjectLogForm from "./ComplaintListForm";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface dropdownDTO {
  text: string;
  value: number;
}

const ComplaintList: React.FC = () => {
  const Card = styled(MuiCard)(spacing);
  const navigate = useNavigate();
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [openFeedbackFormModal, setOpenFeedbackFormModal] = useState(false);
  const [gapAssessmentDto, setGapAssessmentDto] = useState({});
  const handleOpenFeedbackModal = () => setOpenFeedbackModal(true);
  const handleCloseFeedbackModal = () => setOpenFeedbackModal(false);
  const handleOpenFeedbackFormModal = () => setOpenFeedbackFormModal(true);
  const handleCloseFeedbackFormModal = () => setOpenFeedbackFormModal(false);
  const [candidate, setCandidate] = useState({});
  const [uri, setUri] = useState("");
  const [url, setUrl] = useState("/candidate");

  const [selectedProvince, setSelectedProvince] = useState<string | "">("");
  const [selectedSector, setSelectedSector] = useState<string | "">("");
  const [sectors, setSectors] = useState<dropdownDTO[]>([]);
  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const [selectedName, setSelectedName] = useState<string | "">("");
  const { state } = useLocation();
  const { id } = useParams<{ id: string }>();
  const { name } = useParams<{ name: string }>();

  const logColumns: GridColDef[] = [
    {
      field: "issueDescription",
      headerName: "Preocupação",
      flex: 3,
      editable: false,
      renderCell: (params) => (
        <a
          onClick={() => {
            navigate(`/assessment-admin/complaintlist/edit`, {
              state: {
                dto: {
                  ...params.row,
                  candidateName: params?.row?.candidate?.candidateName,
                },
              },
            });
          }}
          style={{
            textDecoration: "none",
            color: "#ec1c24", // Optional: Adjust color for visibility
          }}
        >
          {params.row.issueDescription}
        </a>
      ),
    },
    {
      field: "candidate",
      headerName: "Nome do Candidato",
      flex: 2,
      editable: false,
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.candidate?.candidateName || "N/A"}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Estado",
      flex: 1.5,
      editable: false,
    },
    {
      field: "startDate",
      headerName: "Data de Reporte",
      flex: 2,
      editable: false,
    },
    {
      field: "resolutionDueDate",
      headerName: "Data Prev. de Resolução",
      flex: 2,
      editable: false,
    },
    {
      field: "resolutionDate",
      headerName: "Data de Resolução",
      flex: 2,
      editable: false,
    },
    {
      field: "comments",
      headerName: "Comentários",
      flex: 1.5,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Acções",
      flex: 2,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            navigate(`/assessment-admin/complaintlist/edit`, {
              state: {
                dto: {
                  ...params.row,
                  candidateName: params?.row?.candidate?.candidateName,
                },
              },
            });
          }}
        >
          Editar
        </Button>,
      ],
    },
  ];

  useEffect(() => {
    console.info(id);
    setUri(`/project-log/type/COMPLAINT_LIST`);
  }, [url]);

  return (
    <React.Fragment>
      <OlogaPageHeader
        title={`Lista de Reclamações`}
        buttonLabel="Adicionar Reclamação"
        onClick={() => navigate(`/assessment-admin/complaintlist/edit`)}
      ></OlogaPageHeader>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid url={uri} columns={logColumns} />
          </Card>

          <Modal
            open={openFeedbackFormModal}
            onClose={handleCloseFeedbackFormModal}
          >
            <Box sx={style}>
              <Typography variant="h6" component="h2" mb={2}>
                Formulário de Log de Projeto
              </Typography>
              <ProjectLogForm
                onClose={handleCloseFeedbackFormModal}
                dto={candidate}
                handleOpenGapList={handleOpenFeedbackModal}
              />
            </Box>
          </Modal>
          <OlogaBackButton />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ComplaintList;
