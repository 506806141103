import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Card as MuiCard, Grid, CardContent } from "@mui/material";
import { spacing } from "@mui/system";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import AssessmentForm from "./AssessmentForm";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import http from "../../utils/axios";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React from "react";
import { toolSchema } from "../../utils/formValidation";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import OlogaSelect from "../../components/select/OlogaSelect";
import { OlogaButton } from "../../components/Buttons/OlogaButton";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import BackButton from "../../components/Buttons/BackButton";
interface Rows {
  name: string;
  descrition: number;
  AssessmentId: number;
}

interface RowsState {
  name: string;
  description: string;
  id: number;
  status: string;
}
const Card = styled(MuiCard)(spacing);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface RowInterface {
  name: string;
  description: string;
  id: number;
}

interface ToolFormProps {
  tool?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function AssessmentUpdate() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [tool, setTool] = useState({
    id: 0,
    name: "",
    description: "",
    assessment: { id: state },
  });
  const [entitytype, setEntitytype] = useState([]);
  const [selectedEntitytype, setSelectedEntitytype] = useState();
  const [rows, setRows] = React.useState<RowInterface[]>([]);
  const [rowsStatus, setRowsStatus] = React.useState<RowsState[]>([]);

  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    const data = {
      id: null,
      assessmentId: state.id,
      ...values,
    };
    console.log(data);
    try {
      await http.post(`/tools/create`, data);
      resetForm();
      toast.success("Operação realizada com sucesso");
      handleClose();
      navigate(-1);
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    }
  };
  const handleEntitytypeChange = (value: any) => {
    setSelectedEntitytype(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (state?.candidateId) {
          const result = await http.get(
            `/tools/tool-state/` +
              state.id +
              `?candidateId=${state?.candidateId}`
          );
          setRowsStatus(result?.data as RowsState[]);
        } else {
          const result = await http.get(`/tools/getAllTools/` + state.id);
          setRows(result?.data as RowInterface[]);
        }

        const _entitytype = await http.get(`/simple-entity/entity-types`);
        setEntitytype(_entitytype.data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  return (
    <>
      <OlogaPageHeader
        title="Actualizar Formulário  "
        items={[{ label: "Formulários", url: "/assessments/search" }]}
      />

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <AssessmentForm
                assessment={{
                  id: state?.id,
                  name: "",
                }}
                CandidateView={state?.candidateId ? true : false}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: "10px" }}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <div>
                {state?.candidateId ? (
                  <></>
                ) : (
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="contained"
                      onClick={handleOpen}
                      style={{ marginBottom: "10px" }}
                    >
                      Criar Ferramenta
                    </Button>
                  </div>
                )}

                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Formik
                      initialValues={tool}
                      validationSchema={toolSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ handleSubmit, status, errors }) => (
                        <Card>
                          <CardContent>
                            <form onSubmit={handleSubmit}>
                              <Typography variant="h6" gutterBottom>
                                Criar Ferramenta
                              </Typography>
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <OlogaTextfield name="name" label="Nome" />
                                </Grid>
                                <Grid item xs={12}>
                                  <OlogaSelect
                                    name="description"
                                    options={entitytype}
                                    label="Descrição"
                                    setValue={handleEntitytypeChange}
                                  />
                                </Grid>
                              </Grid>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    handleSubmit();
                                  }}
                                  style={{ marginBottom: "10px" }}
                                >
                                  Criar Ferramenta
                                </Button>
                              </div>
                            </form>
                          </CardContent>
                        </Card>
                      )}
                    </Formik>
                  </Box>
                </Modal>
              </div>
              <TableContainer component={Paper}>
                <Typography variant="h5" gutterBottom marginLeft={4}>
                  Lista de Ferramentas
                </Typography>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Descrição</TableCell>
                      {state?.candidateId ? (
                        <TableCell>Fill State</TableCell>
                      ) : (
                        ""
                      )}
                      <TableCell> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state?.candidateId
                      ? rowsStatus.map((row) => (
                          <TableRow>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>{row.status}</TableCell>
                            <TableCell>
                              {state?.candidateId ? (
                                <>
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    startIcon={<VisibilityOutlinedIcon />}
                                    onClick={() => {
                                      navigate(
                                        `/assessments/assessmentsHistory`,
                                        {
                                          state: {
                                            toolId: row.id,
                                            toolDesc: row.name,
                                            toolCode: row.description,
                                            assessmentId: state?.id,
                                            candidateId: state?.candidateId,
                                          },
                                        }
                                      );
                                    }}
                                  >
                                    Ver Ferramenta
                                  </Button>
                                </>
                              ) : (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  startIcon={<UpdateOutlinedIcon />}
                                  onClick={() => {
                                    navigate(`/tools/edit`, {
                                      state: row.id,
                                    });
                                  }}
                                >
                                  Editar Ferramenta
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                      : rows.map((row) => (
                          <TableRow>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>
                              {state?.candidateId ? (
                                <></>
                              ) : (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  startIcon={<UpdateOutlinedIcon />}
                                  onClick={() => {
                                    navigate(`/tools/edit`, {
                                      state: row.id,
                                    });
                                  }}
                                >
                                  Editar Ferramenta
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <BackButton />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default AssessmentUpdate;
