import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Card as MuiCard, Grid, Modal, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import styled from "@emotion/styled";
import { Box, spacing } from "@mui/system";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaBackButton from "../../components/Buttons/OlogaBackButton";
import ProjectLogForm from "./ProjectLogForm";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface dropdownDTO {
  text: string;
  value: number;
}

const ProjectLogEdit: React.FC = () => {
  const Card = styled(MuiCard)(spacing);
  const navigate = useNavigate();
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [openFeedbackFormModal, setOpenFeedbackFormModal] = useState(false);
  const [gapAssessmentDto, setGapAssessmentDto] = useState({});
  const handleOpenFeedbackModal = () => setOpenFeedbackModal(true);
  const handleCloseFeedbackModal = () => setOpenFeedbackModal(false);
  const handleOpenFeedbackFormModal = () => setOpenFeedbackFormModal(true);
  const handleCloseFeedbackFormModal = () => setOpenFeedbackFormModal(false);
  const [candidate, setCandidate] = useState({});
  const [uri, setUri] = useState("");
  const [url, setUrl] = useState("/candidate");

  const [selectedProvince, setSelectedProvince] = useState<string | "">("");
  const [selectedSector, setSelectedSector] = useState<string | "">("");
  const [sectors, setSectors] = useState<dropdownDTO[]>([]);
  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const [selectedName, setSelectedName] = useState<string | "">("");
  const { state } = useLocation();
  const { id } = useParams<{ id: string }>();
  const { name } = useParams<{ name: string }>();

  return (
    <React.Fragment>
      <OlogaPageHeader
        title={"Formulário de Log de Projecto"}
      ></OlogaPageHeader>
      <Card>
        <ProjectLogForm
          onClose={handleCloseFeedbackFormModal}
          dto={candidate}
          candidateId={id}
          handleOpenGapList={handleOpenFeedbackModal}
        />
      </Card>
    </React.Fragment>
  );
};

export default ProjectLogEdit;
