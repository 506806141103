import { useNavigate } from "react-router-dom";
import { OlogaButton } from "./OlogaButton";

interface OlogaCancelButtonProps {
  label?: string;
  route?: string;
}

function OlogaCancelButton({
  label = "Cancelar",
  route,
}: OlogaCancelButtonProps) {
  const navigate = useNavigate();

  return (
    <OlogaButton
      label={label}
      variant="outlined"
      mr={2}
      onClick={() => (route ? navigate(route) : navigate(-1))}
    />
  );
}

export default OlogaCancelButton;
