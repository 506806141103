import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  CardContent,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
} from "@mui/material";
import { Formik, Form, Field, FormikHelpers, ErrorMessage } from "formik";
import * as Yup from "yup";
import http from "../../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OlogaDatePicker from "../../components/inputfields/OlogaDatePicker";
import OlogaSubmitButton from "../../components/Buttons/OlogaSubmitButton";
import OlogaButtonGroup from "../../components/Buttons/OlogaButtonGroup";
import { formatDateToDdMmYyyy } from "../../utils/utils";
import OlogaSelect from "../../components/select/OlogaSelect";
import Item from "../../components/Combos/Item";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { format, isValid, parse } from "date-fns";

interface LogFormValues {
  id: number;
  candidateId: number;
  projectName: string;
  executionPhase: string;
  responsibleResolution: string;
  approvedInvestment: number;
  executedInvestment: number;
  comments: string;
  beneficiary: string;
  issueDescription: string;
  potentialImpact: string;
  proposedSolution: string;
  resolutionDate: any;
  resolutionDueDate: any;
  //escalationDate: any;
  startDate: any;
  status: string;
}
interface dropdownDTO {
  text: string;
  value: number;
}
const ComplaintListForm: React.FC<{
  onClose: () => void;
  dto: any;
  handleOpenGapList: () => void;
}> = ({ onClose, dto, handleOpenGapList }) => {
  const { state } = useLocation();
  const [initialValues, setInitialValues] = useState<LogFormValues>({
    id: state && state.dto ? state.dto.id ?? "" : "",
    comments: state && state.dto ? state.dto.comments ?? "" : "",
    candidateId: state && state.dto ? state.dto.candidateId ?? "" : "",
    beneficiary: state && state.dto ? state.dto.beneficiary ?? "" : "",
    projectName: state && state.dto ? state.dto.projectName ?? "" : "",
    executionPhase: state && state.dto ? state.dto.executionPhase ?? "" : "",
    responsibleResolution:
      state && state.dto ? state.dto.responsibleResolution ?? "" : "",
    approvedInvestment:
      state && state.dto ? state.dto.approvedInvestment ?? 0 : "",
    executedInvestment:
      state && state.dto ? state.dto.executedInvestment ?? 0 : "",
    issueDescription:
      state && state.dto ? state.dto.issueDescription ?? "" : "",
    potentialImpact: state && state.dto ? state.dto.potentialImpact ?? "" : "",
    proposedSolution:
      state && state.dto ? state.dto.proposedSolution ?? "" : "",
    resolutionDueDate:
      state && state.dto
        ? handleDateConversion(state.dto.resolutionDueDate)
        : "",
    resolutionDate:
      state && state.dto ? handleDateConversion(state.dto.resolutionDate) : "",
    startDate:
      state && state.dto ? handleDateConversion(state.dto.startDate) : "",
    status: state && state.dto ? state.dto.status ?? "" : "",
  });
  const [dropdown_province, setDropdown_province] = useState<dropdownDTO[]>([]);
  const navigate = useNavigate();
  const [province, setProvince] = useState("");
  const [dropdown_activitySector, setDropdown_activitySector] = useState<
    dropdownDTO[]
  >([]);
  const status: Item[] = [
    { id: "OPEN", name: "Aberto" },
    { id: "PROGRESS", name: "Em Progresso" },
    { id: "RESOLVED", name: "Resolvido" },
    { id: "CLOSED", name: "Fechado" },
  ];

  interface Item {
    id: string;
    name: string;
  }

  useEffect(() => {}, []);

  const handleSubmit = async (
    values: LogFormValues,
    actions: FormikHelpers<LogFormValues>
  ) => {
    try {
      values["candidateId"] =
        initialValues.candidateId > 0 ? initialValues.candidateId : 0;
      values["resolutionDueDate"] = format(
        values.resolutionDueDate,
        "dd/MM/yyyy"
      );
      values["resolutionDate"] = format(values.resolutionDate, "dd/MM/yyyy");
      values["startDate"] = format(values.startDate, "dd/MM/yyyy");
      console.log(values);
      const data = { ...values };
      const response = await http.post("/project-log/complaintlist", data);
      if (response.status === 200) {
        toast.success("Operação realizada com sucesso");
        navigate("/assessment-admin/complaintlist");
      }
    } catch (error) {
      toast.error("Ocorreu o seguinte erro ao realizar a operação: " + error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    issueDescription: Yup.string().required("Project Name is required"),
    proposedSolution: Yup.string().required(
      "Approved Investment Category is required"
    ),
  });

  function handleDateConversion(value: any, formatString = "dd/MM/yyyy") {
    if (typeof value === "string") {
      const parsedDate = parse(value, formatString, new Date());
      return isValid(parsedDate) ? parsedDate : "";
    }

    if (value instanceof Date && isValid(value)) {
      return value;
    }

    return ""; // Return empty string for invalid or undefined values
  }

  return (
    <>
      <Card>
        <CardContent>
          <Box>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ values, handleChange, isSubmitting, errors, touched }) => (
                <Form>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={3}>
                      <Grid
                        container
                        alignItems="center"
                        ml={2}
                        mt={3}
                        spacing={2}
                      >
                        <Grid item>
                          <Typography variant="h6">
                            Candidato: {state?.dto?.candidateName}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              navigate(`/assessment-admin/candidateselection`, {
                                state: {
                                  returnUrl: `/assessment-admin/complaintlist/edit`,
                                  dto: {
                                    ...initialValues,
                                  },
                                },
                              });
                            }}
                          >
                            Seleccionar Candidato
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} mt={2}>
                        <Field
                          as={TextField}
                          name="issueDescription"
                          label="Descrição da Reclamação"
                          fullWidth
                          value={values.issueDescription}
                          onChange={handleChange}
                          error={
                            touched?.issueDescription &&
                            Boolean(errors.issueDescription)
                          }
                          helperText={<ErrorMessage name="issueDescription" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="potentialImpact"
                          label="Potencial Impacto"
                          fullWidth
                          value={values.potentialImpact}
                          onChange={handleChange}
                          error={
                            touched.potentialImpact &&
                            Boolean(errors.potentialImpact)
                          }
                          helperText={<ErrorMessage name="potentialImpact" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="responsibleResolution"
                          label="Responsável pela Solução"
                          fullWidth
                          value={values.responsibleResolution}
                          onChange={handleChange}
                          error={
                            touched.responsibleResolution &&
                            Boolean(errors.responsibleResolution)
                          }
                          helperText={
                            <ErrorMessage name="responsibleResolution" />
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <OlogaSelect
                          name="status"
                          options={status as any}
                          label="Estado"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="proposedSolution"
                          label="Proposta de Solução"
                          fullWidth
                          value={values.proposedSolution}
                          onChange={handleChange}
                          error={
                            touched.proposedSolution &&
                            Boolean(errors.proposedSolution)
                          }
                          helperText={<ErrorMessage name="proposedSolution" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <OlogaDatePicker
                          name="resolutionDueDate"
                          label="Data Prevista de Resolução"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <OlogaDatePicker
                          name="startDate"
                          label="Data de Reporte"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <OlogaDatePicker
                          name="resolutionDate"
                          label="Data de Resolução"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          name="comments"
                          label="Comentários"
                          fullWidth
                          value={values.comments}
                          onChange={handleChange}
                          error={touched.comments && Boolean(errors.comments)}
                          helperText={<ErrorMessage name="comments" />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <OlogaButtonGroup />
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default ComplaintListForm;
